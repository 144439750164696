import React from 'react';
import RCSlider, { SliderProps as RCSliderProps } from '@tordek/rc-slider';
import {
  defaultClassName,
  defaultHandleClassName,
  defaultMarksClassName,
  defaultMarkTextClassName,
  defaultRailClassName,
  defaultTrackClassName,
} from './styles';

export type SliderProps<T = number | number[]> = RCSliderProps<T>;
export const Slider = ({
  className,
  railClassName,
  trackClassName,
  handleClassName,
  markTextClassName,
  marksClassName,
  ...props
}: SliderProps) => (
  <RCSlider
    className={className ?? defaultClassName}
    railClassName={railClassName ?? defaultRailClassName}
    trackClassName={trackClassName ?? defaultTrackClassName}
    handleClassName={handleClassName ?? defaultHandleClassName}
    marksClassName={marksClassName ?? defaultMarksClassName}
    markTextClassName={markTextClassName ?? defaultMarkTextClassName}
    {...props}
  />
);
