import React, { FC } from 'react';
import clsx from 'clsx';
import { DataGridErrorProps } from './DataGridError.types';
import { DataGridErrorImg } from './dataGridErrorImg';
import {
  errorStyle,
  imageStyle,
  primaryTextStyle,
  secondaryTextStyle,
  textStyle,
  refreshLinkStyle,
} from './DataGridError.styles';
import { Text } from '../../../Text';
import { Link } from '../../../Buttons';

export const DataGridError: FC<DataGridErrorProps> = ({
  principalText,
  secondaryText,
  refreshText,
  refreshFn,
}) => {
  return (
    <div className={errorStyle}>
      <div className={imageStyle}>
        <DataGridErrorImg />
      </div>
      <Text className={clsx(textStyle, primaryTextStyle)} weight={'medium'}>
        {principalText}
      </Text>
      <Text className={clsx(textStyle, secondaryTextStyle)} weight={'light'}>
        {secondaryText}
      </Text>
      <Link
        className={refreshLinkStyle}
        linkType={'primary'}
        icon={'refresh'}
        size={'big'}
        onClick={() => refreshFn && refreshFn()}
      >
        <Text weight={'medium'}>{refreshText}</Text>
      </Link>
    </div>
  );
};
