import { DataGridTypes } from './enums';
import clsx from 'clsx';

export const typeStyles = {
  [DataGridTypes.FIXED]: clsx('wh-table-fixed'),
  [DataGridTypes.AUTO]: clsx('wh-table-auto'),
};
export const tableStyle = clsx(
  'sm:wh-flex',
  'sm:wh-flex-col',
  'md:wh-bg-white',
  'md:wh-table',
  'wh-w-full',
  'wh-border-separate',
  'wh-border-spacing-0',
  'wh-pb-2',
  'wh-px-2',
);

export const hiddenOnMobile = clsx('sm:wh-hidden');
export const showOnMobile = clsx(
  'sm:wh-flex',
  'sm:grow',
  'sm:wh-flex-col',
  'md:wh-hidden',
  'lg:wh-hidden',
  'xl:wh-hidden',
);
export const headStyles = clsx(
  'wh-bg-white',
  'wh-select-none',
  'wh-sticky',
  'wh-top-0',
  'md:wh-table-header-group',
  'wh-max-h-[48px]',
);

export const headerStyles = clsx(
  'wh-border-b',
  'wh-border-gray-25',
  'wh-text-left',
  'wh-text-secondary-50',
  'wh-pt-2',
);

export const headerContainerStyles = clsx(
  'wh-flex',
  'wh-flex-row',
  'wh-flex-nowrap',
  'wh-pl-2',
  'wh-pt-3',
  'wh-pb-3',
);
export const scrollContent = clsx(
  'wh-h-full',
  'wh-w-full',
  'wh-overflow-y-scroll',
  'wh-overflow-x-hidden',
);

export const headerTitleStyles = clsx('wh-flex', 'wh-flex-col', 'wh-items-end');

export const headerSortIconStyles = clsx(
  'wh-mx-1',
  'wh-flex',
  'wh-flex-col',
  'wh-items-end',
);

export const rowStyles = clsx(
  'wh-border-b',
  'wh-rounded-sm',
  'wh-border-gray-25',
  'wh-bg-white',
  'hover:wh-bg-gray-15',
  'active:wh-bg-gray-15',
  'wh-p-1',
  'md:wh-table-row',
  'last:wh-border-b-0',
);

export const spinnerStyles = clsx(
  'wh-flex',
  'wh-flex-col',
  'wh-items-center',
  'wh-bg-transparent',
);
export const cellStyles = clsx(
  'wh-items-center',
  'wh-px-3',
  'wh-pt-[23px]',
  'wh-pb-[22px]',
  'wh-text-secondary-main',
  'wh-border-b',
  'wh-border-gray-25',
  'wh-min-h-[64px]',
);
