import clsx from 'clsx';

/**
 * @deprecated This component will be removed from Whale in future versions
 */
export const StepListStyle = clsx(
  'wh-flex',
  'wh-flex-col',
  'wh-items-start',
  'wh-p-0',
  'wh-gap-3',
  'md:wh-gap-4',
  'wh-flex-none',
  'wh-self-stretch',
  'wh-grow-0',
);
