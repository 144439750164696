import clsx from 'clsx';
import { SpinnerSizes, SpinnerTypes } from './Spinner.enums';

export const SpinnerStrokeStyles = {
  [SpinnerTypes.WHITE]: 'wh-stroke-white',
  [SpinnerTypes.SECONDARY]: 'wh-stroke-secondary',
  [SpinnerTypes.NEGATIVE]: 'wh-stroke-negative',
  [SpinnerTypes.PRIMARY]: 'wh-stroke-primary',
  [SpinnerTypes.PRIMARY_LIGHT]: 'wh-stroke-primary-light',
};

export const SpinnerDimensionStyles = {
  [SpinnerSizes.SMALL]: clsx('wh-w-4', 'wh-h-4'),
  [SpinnerSizes.MEDIUM]: clsx('wh-w-6', 'wh-h-6'),
  [SpinnerSizes.BIG]: clsx('wh-w-12', 'wh-h-12'),
};
