import clsx from 'clsx';
import { TextSizes } from '../../../common/enums/TextSizes.enum';
import { FontWeights } from '../../../common/enums/FontWeights.enum';

export const commonStyle = clsx(
  'wh-inline-flex',
  'wh-items-center',
  'wh-w-fit',
  'wh-h-6',
  'wh-box-border',
  'wh-px-2',
  'wh-py-1',
  'wh-rounded',
  `wh-text-${TextSizes.DETAILS}`,
  `wh-font-${FontWeights.MEDIUM}`,
  'wh-border',
);

export const primaryTagStyle = clsx(
  'wh-bg-gray-25',
  'wh-text-secondary-50',
  'wh-border-gray-25',
);

export const secondaryTagStyle = clsx(
  'wh-bg-white',
  'wh-text-secondary-main',
  'wh-border-gray-15',
);

export const tertiaryTagStyle = clsx(
  'wh-bg-secondary-dark',
  'wh-text-white',
  'wh-border-secondary-dark',
);
