import clsx from 'clsx';
import { Styles } from '../../../common/enums/Styles.enum';
import { TextBoxState, TextBoxStyle } from './TextBox.types';

export const commonStyle = clsx(
  `wh-flex`,
  `wh-flex-row`,
  `wh-items-center`,
  `wh-py-1 wh-pr-2 wh-pl-1`,
  'wh-gap-5 md:wh-gap-0',
  'wh-border',
  'wh-rounded',
  'wh-flex-none',
  'wh-order-1',
  'wh-grow-0',
);

export const contentStyle = clsx(
  'wh-flex',
  'wh-flex-row',
  'wh-items-start',
  'wh-p0',
  'wh-gap-1',
  'wh-flex-none',
  'wh-order-0',
  'wh-flex-grow-0',
);

export const setIconStyle = (state: TextBoxState) =>
  clsx(
    'wh-w-4',
    'wh-h-4',
    'wh-fill-current',
    `wh-text-${state}-dark`,
    'wh-ml-1',
    'wh-mr-1',
    'wh-mt-1',
  );

export const setColor = (state: TextBoxState, style: TextBoxStyle) =>
  clsx(`wh-text-${state}-dark`, [
    style === Styles.CONTAINED && [`wh-bg-${state}-light`],
    style === Styles.OUTLINED && [`wh-border-${state}-dark`],
  ]);
