import clsx from 'clsx';
import { TextSizes } from '../../../common/enums/TextSizes.enum';

export const commonStyle = clsx(
  'wh-relative',
  'wh-flex',
  'wh-flex-col',
  'wh-items-center',
  'wh-group',
);

export const contentStyle = clsx(
  'wh-absolute',
  'wh-bottom-4',
  'wh-flex',
  'wh-flex-col',
  'wh-items-center',
  'wh-mb-6',
);
export const hidden = clsx('wh-hidden');

export const hover = clsx('wh-hidden', 'group-hover:wh-flex');

export const visible = clsx('wh-flex wh-opacity-100');

export const messageStyle = clsx(
  'wh-relative',
  'wh-z-10',
  'wh-p-2',
  `wh-text-${TextSizes.DETAILS}`,
  'wh-leading-none',
  'wh-text-white',
  'wh-whitespace-no-wrap',
  'wh-bg-secondary-main',
  'wh-shadow-lg',
  'wh-rounded-md',
);
