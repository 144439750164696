import React from 'react';
import { DataGridModalCardProps } from './DataGridModalCard.types';
import { flexRender, RowData } from '@tanstack/react-table';
import {
  modalCardStyle,
  modalCardStyleInner,
  rightCells,
  leftCells,
} from './DataGridModalCard.styles';

export const DataGridModalCard: React.FC<DataGridModalCardProps<RowData>> = ({
  cells,
  mobileViewContent,
}) => {
  return (
    <>
      {mobileViewContent.detailViewGroup.map((matrix, i) => (
        <div className={modalCardStyle} key={i}>
          {matrix.map((item, index) => (
            <div key={index}>
              {cells
                .filter(cell => cell.column.id === item.field)
                .map((cell, index) => (
                  <div className={modalCardStyleInner} key={index}>
                    <div className={leftCells}>
                      <>
                        {flexRender(
                          cell?.column.columnDef.header,
                          cell.getContext(),
                        )}
                      </>
                    </div>
                    <div className={rightCells}>
                      <>
                        {flexRender(
                          cell?.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
