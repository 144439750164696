import clsx from 'clsx';

export const commonStyle = clsx(
  'wh-text-white',
  'wh-rounded-md',
  'wh-font-medium',
  'wh-text-sm',
  'wh-not-italic',
  'wh-leading-6',
  'wh-flex',
  'md:wh-max-w-6xl',
);
export const SnackbarButtonStyle = clsx(
  'wh-text-white',
  'wh-rounded',
  'wh-border-white',
  'wh-border',
  'wh-flex-row',
  'wh-justify-center',
  'wh-items-center',
  'wh-px-3',
  'wh-py-1.5',
  'wh-leading-6',
  'wh-whitespace-nowrap',
  'hover:wh-bg-secondary-light',
  'hover:wh-text-secondary-main',
  'disabled:wh-bg-gray-25',
  'disabled:wh-text-secondary-25',
);

export const blackContainerStyle = clsx('wh-bg-secondary-main');
export const redContainerStyle = clsx('wh-bg-error-main');
export const smallSizeStyle = clsx('wh-py-2', 'wh-px-3');
export const bigSizeStyle = clsx('wh-py-3', 'wh-px-4');

export const buttonRightStyle = clsx('wh-my-auto', 'wh-ml-0');
export const buttonBottomStyle = clsx('wh-w-fit', 'wh-mt-4', 'wh-mb-0');
export const closeButtonStyle = clsx(
  'wh-flex',
  'wh-justify-end',
  'wh-whitespace-nowrap',
  'wh-ml-3',
  'wh-icon',
  'wh-text-xl',
  'wh-cursor-pointer',
  'wh-max-h-6',
);
export const closeWithSnackbarButtonStyle = clsx('wh-max-h-full');
export const dividerStyle = clsx('wh-icon-divider', 'wh-text-sm', 'wh-pr-1');
export const exitStyle = clsx('wh-icon-exit');
export const messageStyle = clsx(
  'wh-m-auto',
  'wh-ml-0',
  'wh-pr-3',
  'wh-word-break',
);
export const flexColumn = clsx('wh-flex-wrap', 'wh-flex-col');
export const leftIconStyle = clsx('wh-text-xl', 'wh-mr-3', 'wh-max-h-10');

export const snackbarHeader = clsx('wh-flex', 'wh-w-full');
export const providerStyle = clsx(
  'wh-px-1.5',
  'wh-py-2',
  'wh-max-w-full',
  'wh-shadow-none',
);
