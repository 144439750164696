export enum IconSizes {
  EXTRA_SMALL = 'extra_small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum IconRadii {
  CIRCLE = 'circle',
  SQUARE = 'square',
}

export enum IconStyles {
  SIMPLE = 'simple',
  FILL = 'fill',
  OUTLINED = 'outlined',
}

export enum IconTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
  PRIMARY = 'primary',
}

export enum IconColors {
  WHITE = 'white',
  GRAY = 'gray',
}
