import React from 'react';
import clsx from 'clsx';
import { WhTagProps, WhTagType } from './WhTag.types';
import {
  commonStyle,
  primaryTagStyle,
  secondaryTagStyle,
  tertiaryTagStyle,
} from './WhTag.styles';

const tagStyles: Record<WhTagType, string> = {
  primary: primaryTagStyle,
  secondary: secondaryTagStyle,
  tertiary: tertiaryTagStyle,
};

export const WhTag: React.FC<WhTagProps> = ({ type, text }) => (
  <div className={clsx(commonStyle, tagStyles[type])}>{text}</div>
);
