import React, {
  ChangeEvent,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
/* cspell:disable-next-line */
import AutoNumeric from 'autonumeric';
import { Icon } from '../../Icons';
import {
  IconColors,
  IconSizes,
  IconStyles,
  IconTypes,
} from '../../Icons/Icon/Icon.enums';
import { TickerSizeEnum, TickerVariantEnum } from './WhTickerInput.enum';
import { getTickerStyles } from './TickerInput.styles';
import {
  TickerVariant,
  WhTickerInputOptionType,
  WhTickerInputProps,
} from './WhTickerInput.types';

const getArrowIconColor = (variant: TickerVariant, disabled: boolean) => {
  let arrowIconColor;

  if (variant === TickerVariantEnum.SECONDARY) {
    arrowIconColor = disabled ? IconColors.GRAY : IconColors.WHITE;
  }

  return arrowIconColor;
};

const TickerInput: <T extends WhTickerInputOptionType>(
  props: WhTickerInputProps<T>,
) => JSX.Element = ({
  tickerOptions,
  tickerSelectedValue,
  labelIcon,
  label,
  errorMessage,
  footer,
  defaultValue,
  tickerOpen,
  value: propValue,
  setValue: setPropValue,
  readOnly = false,
  decimalPlaces = 2,
  inputPlaceholder = '0',
  disabled = false,
  variant = TickerVariantEnum.PRIMARY,
  size = TickerSizeEnum.BIG,
  showSelectedOptionOnDropdown = false,
  onToggleTicker,
  onInputChange,
  onTickerSelected,
  onTickerInputBlur,
}) => {
  const hasValues = tickerOptions.length > 1;
  const inputRef = useRef<HTMLInputElement>(null);
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

  const value = propValue ?? uncontrolledValue;

  const tickerStyles = useMemo(
    () => getTickerStyles(variant, size),
    [variant, size],
  );

  useEffect(() => {
    const autoNumericInstance = new AutoNumeric(inputRef.current!, null, {
      decimalPlaces,
    });

    return () => {
      autoNumericInstance.remove();
    };
  }, [decimalPlaces]);

  useEffect(() => {
    if (!inputRef.current) return;
    const autonumeric = AutoNumeric.getAutoNumericElement(inputRef.current);
    if (value !== autonumeric?.getNumber()) {
      autonumeric?.set(value ?? 0);
    }
  });

  useEffect(() => {
    if (propValue !== undefined && setPropValue === undefined && !readOnly) {
      console.warn(
        "A value was passed for `value` but `setPropValue` isn't set. Either use `defaultValue` for an uncontrolled component, or set `readOnly` if the value cannot change.",
      );
    }

    if (propValue !== undefined && defaultValue !== undefined) {
      console.warn(
        'The value of `defaultValue` is ignored when passing `value`',
      );
    }
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const autonumeric = AutoNumeric.getAutoNumericElement(e.target);
    const inputValue = autonumeric.getNumber() ?? 0;

    onInputChange?.(inputValue);
    setPropValue?.(inputValue);
    if (propValue === undefined) setUncontrolledValue(inputValue);
  };

  return (
    <div className={tickerStyles.wrapperStyles}>
      {!!label && (
        <div className={tickerStyles.labelStyles}>
          <i className={clsx(`wh-icon wh-icon-${labelIcon} wh-mr-1`)} />
          {label}
        </div>
      )}

      <div
        className={tickerStyles.cardStyles(!!errorMessage, !!footer)}
        aria-disabled={disabled}
      >
        {!!errorMessage && (
          <span className={tickerStyles.messageErrorStyles}>
            {errorMessage}
          </span>
        )}
        <div className={tickerStyles.bodyStyles}>
          <input
            ref={inputRef}
            type="text"
            placeholder={inputPlaceholder}
            className={tickerStyles.inputStyles}
            onChange={handleInputChange}
            onBlur={() => onTickerInputBlur?.()}
            disabled={disabled}
            readOnly={readOnly}
            inputMode="decimal"
          />

          <button
            className={tickerStyles.selectedTickerStyles.value(hasValues)}
            disabled={disabled}
            onClick={hasValues ? onToggleTicker : undefined}
          >
            <img
              src={tickerSelectedValue.iconPath}
              alt={tickerSelectedValue.label}
              className={tickerStyles.selectedTickerStyles.icon(!!disabled)}
            />
            <span
              className={tickerStyles.selectedTickerStyles.label(!!disabled)}
            >
              {tickerSelectedValue.label}
            </span>
            {hasValues && (
              <Icon
                icon={'wh-icon-arrow-down'}
                size={IconSizes.EXTRA_SMALL}
                style={IconStyles.SIMPLE}
                type={IconTypes.NEUTRAL}
                color={getArrowIconColor(variant, disabled)}
                animationClass={'wh-rotate-180'}
                animated={tickerOpen}
              />
            )}
          </button>

          <div
            className={tickerStyles.tickerOption.container(
              tickerOpen && !disabled,
            )}
          >
            {tickerOptions.map(
              option =>
                (showSelectedOptionOnDropdown ||
                  option.id !== tickerSelectedValue.id) && (
                  <React.Fragment key={option.id}>
                    <div
                      className={tickerStyles.tickerOption.wrapper}
                      onClick={() => onTickerSelected?.(option)}
                    >
                      <img
                        src={option.iconPath}
                        alt={option.label}
                        className={tickerStyles.tickerOption.icon}
                      />
                      {option.label}
                    </div>
                    <div className={tickerStyles.tickerOption.divider} />
                  </React.Fragment>
                ),
            )}
          </div>
        </div>
        {!!footer && <div className={tickerStyles.footerStyles}>{footer}</div>}
      </div>
    </div>
  );
};

/**
 * WhTickerInput component
 *
 * @example
 * Component structure
 * ```
 * Ticker label
 *  -------------------------------------
 * | Error message                       |
 * |                                     |
 * | Input               ticker select   |
 * |                                     |
 * | Footer                              |
 *  -------------------------------------
 * ```
 */
export const WhTickerInput = memo(TickerInput) as typeof TickerInput;
