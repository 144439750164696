import React, { FC } from 'react';
import { DropdownProps } from './Dropdown.types';
import { Icon } from '../Icon';
import {
  IconRadii,
  IconSizes,
  IconStyles,
  IconTypes,
} from '../Icon/Icon.enums';

export const Dropdown: FC<DropdownProps> = ({ expanded }: DropdownProps) => {
  return (
    <Icon
      icon={'wh-icon-arrow-down'}
      size={IconSizes.EXTRA_SMALL}
      style={IconStyles.FILL}
      type={IconTypes.PRIMARY}
      radius={IconRadii.SQUARE}
      animationClass={'wh-rotate-180'}
      animated={expanded}
    />
  );
};
