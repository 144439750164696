import React from 'react';
import clsx from 'clsx';
import {
  WhModalProps,
  WhModalTitleProps,
  WhModalContentProps,
} from './WhModal.types';
import {
  ModalBackGroundOverlay,
  ModalContainer,
  ModalContent,
  ModalNavBar,
  ModalExitIcon,
  ModalTitleText,
  ModalBody,
} from './WhModal.styles';
import { Text } from '../Text';

export const WhModal = ({
  open = false,
  onClose,
  children,
  className,
  ...props
}: WhModalProps) =>
  open ? (
    <div
      className={clsx('wh-modal', className)}
      role="dialog"
      aria-modal="true"
      aria-labelledby="wh-modal-title"
      {...props}
    >
      <div className={clsx(ModalBackGroundOverlay)}>
        <div className={clsx(ModalContainer)}>
          <div className={clsx(ModalContent)}>
            {React.Children.map(children, (child: React.ReactNode) =>
              React.isValidElement(child) && child.type === WhModalTitle
                ? React.cloneElement(child, { ...child.props, onClose })
                : child,
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;

export const WhModalTitle = ({
  onClose,
  children,
  className,
  size = 'h2-desktop',
  weight = 'medium',
  ...props
}: WhModalTitleProps) => (
  <>
    <div className={clsx(ModalNavBar, className)} {...props}>
      <button className={clsx(ModalExitIcon)} onClick={onClose}>
        <i className={clsx(`wh-icon wh-icon-exit wh-text-2xl`)} />
      </button>
    </div>
    <div className={clsx(ModalTitleText)}>
      <Text size={size} weight={weight}>
        {children}
      </Text>
    </div>
  </>
);

export const WhModalDescription = ({
  children,
  className,
  ...props
}: WhModalContentProps) => (
  <div className={clsx(ModalBody, className)} {...props}>
    <div>{children}</div>
  </div>
);

export default WhModal;
