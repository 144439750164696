import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';
import { WhDataGridProps } from './WhDataGrid.types';
import { DataGridTypes } from './enums';
import { DataGrid } from './DataGrid';

const queryClient = new QueryClient();

export const WhDataGrid: FC<WhDataGridProps> = ({
  columns,
  dataGridType = DataGridTypes.AUTO,
  loadServerRows,
  fetchSize = 10,
  emptyConfig,
  errorConfig,
  mobileViewContent,
  scrollLimitToFetch = 100,
  firstLoadLimit,
  ancestorRef,
}) => (
  <QueryClientProvider client={queryClient}>
    <DataGrid
      columns={columns}
      dataGridType={dataGridType}
      loadServerRows={loadServerRows}
      fetchSize={fetchSize}
      emptyConfig={emptyConfig}
      errorConfig={errorConfig}
      mobileViewContent={mobileViewContent}
      scrollLimitToFetch={scrollLimitToFetch}
      firstLoadLimit={firstLoadLimit}
      ancestorRef={ancestorRef}
    />
  </QueryClientProvider>
);
