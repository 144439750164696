import React, { FC } from 'react';
import clsx from 'clsx';
import { ButtonProps, ButtonSize } from './Button.types';
import { ButtonSizes, ButtonTypes, ButtonVariants } from './Button.enums';
import {
  commonStyle,
  iconSizeStyles,
  sizeStyles,
  spinnerSize,
  spinnerType,
  typeStyles,
} from './Button.styles';
import { IconPositions } from '../../../common/enums/IconPositions.enum';
import { Spinner } from '../../Icons';

const renderIcon = (icon: string, size: ButtonSize, margin: string) => (
  <i
    className={clsx(`wh-icon wh-icon-${icon}`, iconSizeStyles[size], margin)}
  />
);

export const Button: FC<ButtonProps> = ({
  className,
  children,
  loading,
  disabled,
  variant = ButtonVariants.CONTAINED,
  buttonType = ButtonTypes.PRIMARY,
  size = ButtonSizes.SMALL,
  icon,
  iconPosition = IconPositions.RIGHT,
  ...props
}: ButtonProps) => (
  <button
    className={clsx(
      className,
      commonStyle,
      typeStyles[buttonType][variant],
      sizeStyles[size],
    )}
    disabled={disabled || loading}
    {...props}
  >
    <span
      className={clsx('wh-flex', 'wh-justify-center', {
        'wh-invisible': loading,
      })}
    >
      {icon &&
        iconPosition === IconPositions.LEFT &&
        renderIcon(icon, size, 'wh-mr-3')}
      {children}
      {icon &&
        iconPosition === IconPositions.RIGHT &&
        renderIcon(icon, size, 'wh-ml-3')}
    </span>
    {loading && (
      <Spinner
        className="wh-absolute"
        size={spinnerSize[size]}
        type={spinnerType[buttonType][variant]}
      />
    )}
  </button>
);
