import clsx from 'clsx';

export const errorStyle = clsx(
  'wh-flex',
  'wh-bg-white',
  'wh-flex-col',
  'wh-items-center',
);

export const imageStyle = clsx('wh-mt-4', 'wh-m-1');

export const textStyle = clsx('wh-m-1', 'wh-text-secondary-main');

export const primaryTextStyle = clsx('wh-text-h5', 'wh-mt-6');
export const secondaryTextStyle = clsx('wh-text-body1 wh-mt-1 wh-mb-6 wh-mx-4');
export const refreshLinkStyle = clsx('wh-mb-6');
