import clsx from 'clsx';

export const cardStyle = clsx(
  'wh-grid',
  'wh-p-3',
  'wh-mb-3',
  'hover:wh-bg-gray-15',
  'active:wh-bg-gray-15',
);

export const topCells = clsx(
  'wh-grid',
  'wh-grid-cols-2',
  'wh-grid-rows-1',
  'wh-items-center',
  'wh-gap-x-3',
  'wh-mb-1',
);
export const bottomCells = clsx(
  'wh-grid',
  'wh-grid-cols-2',
  'wh-grid-rows-1',
  'wh-items-center',
  'wh-gap-x-3',
  'wh-mt-1',
);

export const innerCells = clsx('odd:wh-text-left', 'even:wh-text-right');
