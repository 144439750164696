import React from 'react';
import { Row } from '@tanstack/react-table';
import { showOnMobile } from '../../WhDataGrid.styles';
import { MobileViewContent } from '../../WhDataGrid.types';
import { DataGridCard } from '../DataGridCard/DataGridCard';

export type DataGridRowProps = {
  row: Row<unknown>;
  mobileViewContent: MobileViewContent;
  toggleModal: () => void | Promise<void>;
};
export const DataGridRowMobile: React.FC<DataGridRowProps> = ({
  row,
  mobileViewContent,
  toggleModal,
}) => {
  const getMobileCells = () => {
    return row
      .getVisibleCells()
      .filter(cell =>
        mobileViewContent.cardViewFields.some(col =>
          col.some(item => item.field === cell.column.id),
        ),
      );
  };
  const cardViewFields = mobileViewContent.cardViewFields;
  return (
    <div className={showOnMobile} onClick={toggleModal}>
      <DataGridCard cells={getMobileCells()} cardViewFields={cardViewFields} />
    </div>
  );
};
