import React from 'react';

export const DataGridEmptyImg = () => (
  <svg
    width="124"
    height="124"
    viewBox="0 0 168 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M101.079 118.792C110.582 118.792 118.286 111.088 118.286 101.585C118.286 92.0814 110.582 84.3774 101.079 84.3774C91.5755 84.3774 83.8716 92.0814 83.8716 101.585C83.8716 111.088 91.5755 118.792 101.079 118.792Z"
      fill="#E2EAF4"
    />
    <path
      d="M118.283 101.585C118.282 105.01 117.259 108.358 115.345 111.199C113.43 114.04 110.711 116.245 107.536 117.532C104.361 118.818 100.874 119.128 97.5218 118.422C94.1696 117.715 91.1045 116.024 88.719 113.565C92.3406 115.482 96.5273 116.044 100.526 115.15C104.525 114.256 108.074 111.964 110.534 108.687C112.994 105.41 114.205 101.363 113.947 97.2737C113.69 93.1841 111.982 89.3205 109.131 86.3777C111.895 87.8408 114.208 90.0298 115.82 92.7093C117.433 95.3887 118.284 98.4572 118.283 101.585Z"
      fill="#DCE3EB"
    />
    <path
      d="M101.079 114.49C108.206 114.49 113.984 108.712 113.984 101.585C113.984 94.4571 108.206 88.6792 101.079 88.6792C93.9515 88.6792 88.1736 94.4571 88.1736 101.585C88.1736 108.712 93.9515 114.49 101.079 114.49Z"
      fill="#CDD5DE"
    />
    <path
      d="M102.068 94.6907C101.455 94.3419 100.703 94.3419 100.09 94.6907L95.7807 97.142C95.1556 97.4975 94.7695 98.1613 94.7695 98.8804V103.715C94.7695 104.434 95.1556 105.098 95.7807 105.454L100.09 107.905C100.703 108.254 101.455 108.254 102.068 107.905L106.377 105.454C107.002 105.098 107.388 104.434 107.388 103.715V98.8804C107.388 98.1613 107.002 97.4975 106.377 97.142L102.068 94.6907Z"
      fill="#B8C0CB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.165 96.7133L105.27 99.0794V102.158C105.27 102.572 104.934 102.908 104.52 102.908C104.106 102.908 103.77 102.572 103.77 102.158V99.9462L101.133 98.426L99.1342 99.48C98.7678 99.6732 98.3142 99.5328 98.1209 99.1664C97.9277 98.8001 98.0681 98.3464 98.4345 98.1532L101.165 96.7133Z"
      fill="#CDD5DE"
    />
    <path
      d="M106.758 113.178C105.107 113.987 103.302 114.433 101.464 114.488C99.6263 114.543 97.7981 114.204 96.1018 113.495C94.4056 112.786 92.8804 111.722 91.6284 110.376C90.3765 109.029 89.4266 107.431 88.8424 105.688C88.2583 103.945 88.0533 102.097 88.2412 100.268C88.4291 98.4389 89.0056 96.6713 89.932 95.0833C90.8585 93.4952 92.1135 92.1234 93.6131 91.0598C95.1127 89.9961 96.8222 89.2651 98.6272 88.9156C96.0085 90.1992 93.9017 92.3316 92.6499 94.9656C91.3981 97.5996 91.0751 100.58 91.7336 103.421C92.3921 106.262 93.9932 108.796 96.2762 110.611C98.5591 112.426 101.389 113.414 104.306 113.414C105.129 113.418 105.95 113.338 106.758 113.178Z"
      fill="#B8BEC5"
    />
    <path
      d="M80.2072 79.4143C89.7104 79.4143 97.4143 71.7104 97.4143 62.2072C97.4143 52.7039 89.7104 45 80.2072 45C70.7039 45 63 52.7039 63 62.2072C63 71.7104 70.7039 79.4143 80.2072 79.4143Z"
      fill="#E2EAF4"
    />
    <path
      d="M97.4115 62.2071C97.4107 65.633 96.3875 68.9807 94.4729 71.8217C92.5584 74.6627 89.8395 76.8676 86.6644 78.1543C83.4893 79.441 80.0024 79.7508 76.6502 79.0441C73.298 78.3375 70.2329 76.6465 67.8474 74.1876C71.469 76.1045 75.6557 76.6665 79.6546 75.7723C83.6534 74.8782 87.2021 72.5866 89.6623 69.3098C92.1226 66.0329 93.3329 61.9858 93.0758 57.8963C92.8186 53.8067 91.1108 49.9431 88.2594 47.0002C91.0234 48.4633 93.3361 50.6524 94.9487 53.3318C96.5613 56.0113 97.4128 59.0798 97.4115 62.2071Z"
      fill="#DCE3EB"
    />
    <path
      d="M80.2074 75.1125C87.3348 75.1125 93.1128 69.3346 93.1128 62.2071C93.1128 55.0797 87.3348 49.3018 80.2074 49.3018C73.0799 49.3018 67.302 55.0797 67.302 62.2071C67.302 69.3346 73.0799 75.1125 80.2074 75.1125Z"
      fill="#CDD5DE"
    />
    <path
      d="M81.1961 55.3132C80.583 54.9645 79.8315 54.9645 79.2184 55.3132L74.9091 57.7645C74.284 58.1201 73.8979 58.7838 73.8979 59.503V64.3378C73.8979 65.0569 74.284 65.7207 74.9091 66.0762L79.2184 68.5275C79.8315 68.8763 80.583 68.8763 81.1961 68.5275L85.5054 66.0762C86.1305 65.7207 86.5165 65.0569 86.5165 64.3378V59.503C86.5165 58.7838 86.1305 58.1201 85.5054 57.7645L81.1961 55.3132Z"
      fill="#B8C0CB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.2932 57.3358L84.3985 59.702V62.7808C84.3985 63.195 84.0627 63.5308 83.6485 63.5308C83.2343 63.5308 82.8985 63.195 82.8985 62.7808V60.5687L80.2611 59.0486L78.2626 60.1025C77.8962 60.2958 77.4426 60.1554 77.2494 59.789C77.0561 59.4226 77.1965 58.969 77.5629 58.7757L80.2932 57.3358Z"
      fill="#CDD5DE"
    />
    <path
      d="M85.886 73.8003C84.2349 74.6091 82.4301 75.0557 80.5924 75.1104C78.7547 75.1651 76.9265 74.8265 75.2303 74.1174C73.534 73.4082 72.0088 72.3448 70.7569 70.9984C69.5049 69.6521 68.555 68.0537 67.9709 66.3105C67.3867 64.5672 67.1817 62.7193 67.3696 60.8904C67.5575 59.0615 68.134 57.2939 69.0604 55.7058C69.9869 54.1178 71.2419 52.746 72.7415 51.6823C74.2411 50.6186 75.9506 49.8876 77.7556 49.5382C75.137 50.8217 73.0301 52.9542 71.7783 55.5882C70.5265 58.2222 70.2035 61.2025 70.862 64.0435C71.5205 66.8845 73.1217 69.4188 75.4046 71.2335C77.6875 73.0482 80.5177 74.0363 83.434 74.0369C84.2572 74.0402 85.0787 73.9609 85.886 73.8003Z"
      fill="#B8BEC5"
    />
    <path
      d="M89.4228 29.7093L83.247 35.8853C82.9299 36.2022 82.7555 36.6239 82.7555 37.0723V44.9201C85.2643 45.3614 87.2611 46.0525 89.4954 47.4482V38.7394L96.1553 32.0795L93.7854 29.7093C93.2027 29.1266 92.4281 28.8059 91.6043 28.8059C90.7801 28.8062 90.0055 29.1266 89.4228 29.7093Z"
      fill="#E1AC95"
    />
    <path
      d="M152.468 16.1204H89.9848C88.3592 16.1204 86.8002 16.7662 85.6508 17.9155L73.1523 30.4141C72.0029 31.5634 71.3572 33.1224 71.3572 34.748V47.0954C75.0072 45.165 77.237 44.8484 80.2215 44.7991C81.2297 44.7991 82.3308 44.8499 83.285 45.0353V37.1927C83.285 36.8199 83.4332 36.4623 83.6969 36.1987L89.8726 30.023C90.9706 28.925 92.751 28.925 93.849 30.023L101.179 37.3532C102.277 38.4512 102.277 40.2316 101.179 41.3296L90.3633 52.1455C87.9118 54.597 87.9118 58.5721 90.3633 61.0236C92.8148 63.4751 96.7899 63.4751 99.2414 61.0236L112.567 47.6975C117.875 47.1224 122.858 44.7582 126.668 40.9475C127.75 39.865 129.219 39.2578 130.75 39.2578H152.468C153.314 39.2578 154 38.5719 154 37.7255V17.6527C154 16.8063 153.314 16.1204 152.468 16.1204Z"
      fill="url(#paint0_linear_1977_1009)"
    />
    <path
      d="M77.6859 36.3861C77.6859 34.6041 78.3939 32.895 79.654 31.6349L93.3565 17.9324C94.4539 16.8349 95.8918 16.1569 97.4218 16H89.7281C88.1025 16 86.5434 16.6458 85.3941 17.7951L72.8956 30.2936C71.7462 31.443 71.1005 33.002 71.1005 34.6276V47.356C73.1824 46.0525 75.2545 45.2969 77.6859 44.9153V36.3861Z"
      fill="#E1AC95"
    />
    <path
      d="M123.857 123.854C122.907 120.815 119.655 119.141 116.63 120.135L95.4298 127.101C95.7677 127.783 95.9629 128.547 95.9629 129.358C95.9629 132.171 93.6743 134.459 90.8612 134.459H43.6135V143.009L74.2901 149.529C77.4649 150.091 80.7355 149.653 83.6505 148.275L121.179 130.544C123.545 129.232 124.664 126.437 123.857 123.854Z"
      fill="url(#paint1_linear_1977_1009)"
    />
    <path
      d="M90.8612 123.983H77.1426C74.9536 118.197 69.365 114.082 62.8126 114.082H43.6135V134.732H90.8612C93.8296 134.732 96.2358 132.326 96.2358 129.357C96.2358 126.389 93.8296 123.983 90.8612 123.983Z"
      fill="url(#paint2_linear_1977_1009)"
    />
    <path
      d="M43.8865 151.39V107.301C43.8865 106.547 43.2754 105.936 42.5218 105.936H15.3647C14.6111 105.936 14 106.547 14 107.301V151.39C14 152.144 14.6111 152.755 15.3647 152.755H42.5218C43.2754 152.755 43.8865 152.144 43.8865 151.39Z"
      fill="url(#paint3_linear_1977_1009)"
    />
    <path
      opacity="0.5"
      d="M55.4766 85C55.617 85 55.6638 85.2564 55.6638 85.2564C55.6638 85.3077 55.6638 85.4103 55.7106 85.4615C56.3191 88.0256 58.1915 90.0769 60.5319 90.7436C60.5787 90.7436 60.6723 90.7436 60.766 90.7949C60.766 90.7949 61 90.7949 61 91C61 91.2051 60.766 91.2051 60.766 91.2051C60.7191 91.2051 60.6255 91.2051 60.5319 91.2564C58.1915 91.9231 56.366 93.9744 55.7106 96.5385C55.7106 96.5897 55.7106 96.6923 55.6638 96.7436C55.6638 96.7436 55.6638 97 55.4766 97C55.2894 97 55.2894 96.7436 55.2894 96.7436C55.2894 96.6923 55.2894 96.5897 55.2426 96.5385C54.634 93.9744 52.7617 91.9231 50.4213 91.2564C50.3277 91.2564 50.2809 91.2564 50.234 91.2051C50.234 91.2051 50 91.2051 50 91C50 90.7949 50.234 90.7949 50.234 90.7949C50.2809 90.7949 50.3745 90.7949 50.4213 90.7436C52.7617 90.0769 54.634 88.0256 55.2426 85.4615C55.2426 85.4103 55.2426 85.3077 55.2894 85.2564C55.2894 85.2564 55.2894 85 55.4766 85Z"
      fill="#8E99A3"
    />
    <path
      opacity="0.5"
      d="M114.485 58C114.574 58 114.604 58.1709 114.604 58.1709C114.604 58.2051 114.604 58.2735 114.634 58.3077C115.021 60.0171 116.213 61.3846 117.702 61.8291C117.732 61.8291 117.791 61.8291 117.851 61.8632C117.851 61.8632 118 61.8632 118 62C118 62.1368 117.851 62.1368 117.851 62.1368C117.821 62.1368 117.762 62.1368 117.702 62.1709C116.213 62.6154 115.051 63.9829 114.634 65.6923C114.634 65.7265 114.634 65.7949 114.604 65.8291C114.604 65.8291 114.604 66 114.485 66C114.366 66 114.366 65.8291 114.366 65.8291C114.366 65.7949 114.366 65.7265 114.336 65.6923C113.949 63.9829 112.757 62.6154 111.268 62.1709C111.209 62.1709 111.179 62.1709 111.149 62.1368C111.149 62.1368 111 62.1368 111 62C111 61.8632 111.149 61.8632 111.149 61.8632C111.179 61.8632 111.238 61.8632 111.268 61.8291C112.757 61.3846 113.949 60.0171 114.336 58.3077C114.336 58.2735 114.336 58.2051 114.366 58.1709C114.366 58.1709 114.366 58 114.485 58Z"
      fill="#8E99A3"
    />
    <path
      d="M130.97 73C131.149 73 131.209 73.3419 131.209 73.3419C131.209 73.4103 131.209 73.547 131.268 73.6154C132.043 77.0342 134.426 79.7692 137.404 80.6581C137.464 80.6581 137.583 80.6581 137.702 80.7265C137.702 80.7265 138 80.7265 138 81C138 81.2735 137.702 81.2735 137.702 81.2735C137.643 81.2735 137.523 81.2735 137.404 81.3419C134.426 82.2308 132.102 84.9658 131.268 88.3846C131.268 88.453 131.268 88.5897 131.209 88.6581C131.209 88.6581 131.209 89 130.97 89C130.732 89 130.732 88.6581 130.732 88.6581C130.732 88.5897 130.732 88.453 130.672 88.3846C129.898 84.9658 127.515 82.2308 124.536 81.3419C124.417 81.3419 124.357 81.3419 124.298 81.2735C124.298 81.2735 124 81.2735 124 81C124 80.7265 124.298 80.7265 124.298 80.7265C124.357 80.7265 124.477 80.7265 124.536 80.6581C127.515 79.7692 129.898 77.0342 130.672 73.6154C130.672 73.547 130.672 73.4103 130.732 73.3419C130.732 73.3419 130.732 73 130.97 73Z"
      fill="#8E99A3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1977_1009"
        x1="82.8286"
        y1="39.5347"
        x2="128.035"
        y2="67.7372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBDBBC" />
        <stop offset="1" stop-color="#DFA993" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1977_1009"
        x1="127.932"
        y1="139.649"
        x2="103.053"
        y2="102.789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBDBBC" />
        <stop offset="1" stop-color="#DFA993" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1977_1009"
        x1="83.6108"
        y1="133.392"
        x2="74.7037"
        y2="102.798"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBDBBC" />
        <stop offset="1" stop-color="#DFA993" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1977_1009"
        x1="28.9432"
        y1="105.936"
        x2="28.9432"
        y2="152.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DAE2EC" />
        <stop offset="1" stop-color="#C9D1DA" />
      </linearGradient>
    </defs>
  </svg>
);
