import clsx from 'clsx';
import { TextSizes } from '../../../common/enums/TextSizes.enum';

export const parentContent = clsx('wh-font-medium', 'wh-flex', 'wh-flex-row');

export const iconStyle = clsx(
  `wh-text-${TextSizes.H4}`,
  'wh-w-4',
  'wh-h-4',
  'wh-fill-current',
  'wh-text-primary-main',
  'wh-flex',
  'wh-flex-row',
  'wh-justify-end',
  'hover:wh-text-primary-75',
  'active:wh-text-primary-75',
  'disabled:hover:wh-bg-primary-25',
  'wh-ml-2',
);

export const primaryContent = clsx(
  'wh-text-left',
  'wh-break-all',
  'wh-order-first',
  'wh-grow',
);

export const secondaryContent = clsx(
  'wh-margin-left-1',
  'wh-order-last',
  'wh-grow-0',
  'wh-self-center',
  'wh-ml-2',
  'wh-border-l-2',
  'wh-border-primary-25',
);
