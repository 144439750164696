import React from 'react';
import { CurrencyFormat } from '@securitize/commons-i18n-formatter';
import { FontWeight, TextSize, Text } from '../../../Text';
import { DataGridCellBaseProps } from '../../DataGridCell.types';

export type DataGridAmountCellProps = {
  size?: TextSize;
  weight?: FontWeight;
  currencyFormatter: CurrencyFormat;
};
export const DataGridAmountCell: React.FC<
  DataGridCellBaseProps<number | string | undefined, DataGridAmountCellProps>
> = ({ value, cellProps }) => {
  const { currencyFormatter, size, weight } = cellProps;

  let formattedValue = '-';

  if (value) {
    formattedValue = currencyFormatter.format(parseFloat(value as string));
  }

  const textOptions = {
    size,
    weight,
  };

  return <Text {...textOptions}>{formattedValue}</Text>;
};
