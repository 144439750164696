import clsx from 'clsx';

/**
 * @deprecated This component will be removed from Whale in future versions
 */
export const StepStyle = clsx(
  'wh-box-border',
  'wh-flex',
  'wh-flex-column',
  'wh-align-start',
  'sm:wh-pt-4',
  'sm:wh-px-4',
  'sm:wh-pb-6',
  'md:wh-p-8',
  'wh-rounded-100',
  'wh-border',
  'wh-border-solid',
  'wh-highlighted-border',
  'wh-flex-none',
  'wh-order-1',
  'md:wh-order-0',
  'wh-self-stretch',
  'wh-grow-0',
);

/**
 * @deprecated This component will be removed from Whale in future versions
 */
export const StepNumberStyle = clsx(
  'wh-flex',
  'wh-flex-row',
  'wh-items-center',
  'wh-p-0',
  'wh-gap-2.5',
  'wh-flex-none',
  'wh-grow-0',
);
