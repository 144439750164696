import React, { FC } from 'react';
import clsx from 'clsx';
import { IconProps } from './Icon.types';
import {
  backgroundStyles,
  borderStyles,
  containerSizeStyles,
  containerStyle,
  iconAnimationStyles,
  iconTypeColorStyles,
  iconColorStyles,
  iconSizeStyles,
  radiusStyles,
  styleStyles,
} from './Icon.styles';
import { IconTypes, IconRadii, IconSizes, IconStyles } from './Icon.enums';

export const Icon: FC<IconProps> = ({
  icon,
  size = IconSizes.EXTRA_SMALL,
  style = IconStyles.SIMPLE,
  type = IconTypes.NEUTRAL,
  radius = IconRadii.SQUARE,
  animated,
  animationClass,
  color,
}: IconProps) => {
  const iconColor =
    (color && iconColorStyles[color]) ?? iconTypeColorStyles[type];

  return (
    <div
      className={clsx(
        containerStyle,
        containerSizeStyles[size],
        styleStyles[style],
        [style === IconStyles.FILL && backgroundStyles[type]],
        borderStyles[type],
        radiusStyles[radius],
      )}
    >
      <i
        className={clsx(
          icon,
          iconColor,
          [animated && animationClass],
          iconAnimationStyles,
          iconSizeStyles[size],
        )}
      />
    </div>
  );
};
