import React, { FC } from 'react';
import clsx from 'clsx';
import { CardProps } from './Card.types';
import { setCardSize } from './Card.styles';

export const Card: FC<CardProps> = ({
  children,
  className,
  size,
  ...extraProps
}) => {
  return (
    <div
      className={clsx('wh-card', setCardSize(size ?? 'none'), className)}
      {...extraProps}
    >
      {children}
    </div>
  );
};
