import React, { FC } from 'react';
import clsx from 'clsx';
import { TextProps } from './Text.types';
import { TextSizes } from '../../common/enums/TextSizes.enum';
import { setTextSize, setFontWeight } from './Text.styles';

export const Text: FC<TextProps> = ({
  className,
  children,
  size = TextSizes.NONE,
  weight,
  ...props
}: TextProps) => (
  <span
    className={clsx(
      className,
      setTextSize(size),
      weight && setFontWeight(weight),
    )}
    {...props}
  >
    {children}
  </span>
);
