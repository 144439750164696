import clsx from 'clsx';
import {
  IconColors,
  IconRadii,
  IconSizes,
  IconStyles,
  IconTypes,
} from './Icon.enums';

export const containerStyle = clsx('wh-flex', 'wh-justify-center');

export const styleStyles = {
  [IconStyles.SIMPLE]: clsx('wh-bg-transparent', 'wh-border-none'),
  [IconStyles.FILL]: clsx('wh-border-none'),
  [IconStyles.OUTLINED]: clsx('wh-border-solid', 'wh-border'),
};

export const radiusStyles = {
  [IconRadii.SQUARE]: clsx('wh-rounded-md'),
  [IconRadii.CIRCLE]: clsx('wh-rounded-full'),
};

export const backgroundStyles = {
  [IconTypes.NEUTRAL]: clsx('wh-bg-gray-5'),
  [IconTypes.PRIMARY]: clsx('wh-bg-primary-light'),
  [IconTypes.ERROR]: clsx('wh-bg-error-light'),
  [IconTypes.WARNING]: clsx('wh-bg-warning-light'),
  [IconTypes.SUCCESS]: clsx('wh-bg-success-light'),
};

export const borderStyles = {
  [IconTypes.NEUTRAL]: clsx('wh-border-gray-5'),
  [IconTypes.PRIMARY]: clsx('wh-border-primary-light'),
  [IconTypes.ERROR]: clsx('wh-border-error-light'),
  [IconTypes.WARNING]: clsx('wh-border-warning-light'),
  [IconTypes.SUCCESS]: clsx('wh-border-success-light'),
};

export const containerSizeStyles = {
  [IconSizes.EXTRA_SMALL]: clsx('wh-h-6', 'wh-w-6'),
  [IconSizes.SMALL]: clsx('wh-h-8', 'wh-w-8'),
  [IconSizes.MEDIUM]: clsx('wh-h-12', 'wh-w-12'),
  [IconSizes.LARGE]: clsx('wh-h-16', 'wh-w-16'),
};

export const iconSizeStyles = {
  [IconSizes.EXTRA_SMALL]: clsx('wh-text-base'),
  [IconSizes.SMALL]: clsx('wh-text-xl'),
  [IconSizes.MEDIUM]: clsx('wh-text-icon-medium'),
  [IconSizes.LARGE]: clsx('wh-text-5xl'),
};

export const iconAnimationStyles = clsx('wh-transition-all', 'wh-duration-500');

export const iconTypeColorStyles = {
  [IconTypes.NEUTRAL]: clsx('wh-text-black'),
  [IconTypes.PRIMARY]: clsx('wh-text-primary-main'),
  [IconTypes.ERROR]: clsx('wh-text-error-main'),
  [IconTypes.WARNING]: clsx('wh-text-warning-main'),
  [IconTypes.SUCCESS]: clsx('wh-text-success-main'),
};

export const iconColorStyles = {
  [IconColors.WHITE]: clsx('wh-text-white'),
  [IconColors.GRAY]: clsx('wh-text-secondary-50'),
};
