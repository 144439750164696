import React from 'react';
import clsx from 'clsx';
import { flexRender, Row } from '@tanstack/react-table';
import { rowStyles, cellStyles, hiddenOnMobile } from '../../WhDataGrid.styles';

export type DataGridRowProps = {
  row: Row<unknown>;
};
export const DataGridRow: React.FC<DataGridRowProps> = ({ row }) => {
  return (
    <>
      <tr className={clsx(rowStyles, hiddenOnMobile)}>
        {row.getVisibleCells().map(cell => (
          <td
            key={cell.id}
            className={clsx(cellStyles)}
            style={{
              width: cell.column.getSize(),
            }}
          >
            <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
          </td>
        ))}
      </tr>
    </>
  );
};
