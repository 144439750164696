import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { CopyButtonProps } from './CopyButton.types';
import {
  iconStyle,
  parentContent,
  primaryContent,
  secondaryContent,
} from './CopyButton.styles';
import { Tooltip } from '../../Dialogs/Tooltip';
import { Button } from '../Button';
import { ButtonTypes } from '../Button/Button.enums';
import { Text } from '../../Text/Text';
import { TextSizes } from '../../../common/enums/TextSizes.enum';

export const CopyButton: FC<CopyButtonProps> = ({
  copyValue,
  text,
  size = 'lg',
  className,
  tooltipText,
  messageDuration,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const copyAction = () => {
    setIsVisible(true);
    navigator.clipboard.writeText(copyValue ?? text);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, messageDuration ?? 2000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  return (
    <>
      <Button
        buttonType={ButtonTypes.CLIP}
        className={className}
        onClick={copyAction}
      >
        <Tooltip
          message={tooltipText ?? 'Copied!'}
          type={'click'}
          isVisible={isVisible}
        >
          <Text
            size={size === 'lg' ? TextSizes.H5 : TextSizes.DETAILS}
            className={parentContent}
          >
            <div className={primaryContent}>{text}</div>
            <div className={secondaryContent}>
              <i className={clsx(`wh-icon wh-icon-copy`, iconStyle)} />
            </div>
          </Text>
        </Tooltip>
      </Button>
    </>
  );
};
