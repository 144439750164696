import clsx from 'clsx';

export const ModalBackGroundOverlay = clsx(
  'wh-transition',
  'wh-duration-150',
  'wh-ease-in-out',
  'wh-absolute',
  'wh-w-full',
  'wh-h-full',
  'wh-top-0',
  'wh-left-0',
  'wh-right-0',
  'wh-bottom-0',
  'wh-z-1000',
  'wh-bg-white',
);

export const ModalContainer = clsx('wh-w-full');

export const ModalContent = clsx('wh-relative', 'wh-bg-white', 'wh-h-screen');

export const ModalNavBar = clsx(
  'wh-items-end',
  'wh-p-4',
  'wh-gap-1',
  'wh-text-right',
);

export const ModalExitIcon = clsx(
  'wh-text-right',
  'wh-p-0',
  'wh-gap-1',
  'wh-cursor-pointer',
  'wh-text-black',
  'wh-min-w-1',
  'wh-min-h-1',
);

export const ModalTitleText = clsx('wh-flex-auto', 'wh-m-0', 'wh-px-4');

export const ModalBody = clsx('wh-flex-auto', 'wh-p-4', 'wh-overflow-y-auto');
