import React, { FC } from 'react';
import clsx from 'clsx';
import { TooltipProps } from './Tooltip.types';
import {
  commonStyle,
  contentStyle,
  hidden,
  hover,
  messageStyle,
  visible,
} from './Tooltip.styles';

/**
 * @deprecated Please use WhTooltip component instead
 */
export const Tooltip: FC<TooltipProps> = ({
  message,
  children,
  type,
  isVisible,
}) => {
  return (
    <div className={commonStyle}>
      {children}
      <div
        className={clsx(
          contentStyle,
          [type === 'hover' && hover],
          [type === 'click' && isVisible ? visible : hidden],
        )}
      >
        <span className={messageStyle}>{message}</span>
      </div>
    </div>
  );
};
