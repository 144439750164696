import clsx from 'clsx';

export const containerStyle = clsx(
  'wh-inline-flex',
  'wh-group',
  'wh-whitespace-no-wrap',
);

export const relativePosition = clsx('wh-relative');
export const staticPosition = clsx('wh-static');

export const topLeftStyle = clsx(
  'after:wh-top-full after:wh-border-b-transparent after:wh-left-2 -wh-left-1.5',
);
export const topRightStyle = clsx(
  'after:wh-top-full after:wh-border-b-transparent after:wh-right-0 after:-wh-translate-x-1/2 -wh-right-1.5',
);
export const topCenterStyle = clsx(
  'after:wh-top-full after:wh-border-b-transparent after:wh-left-1/2 after:-wh-translate-x-1/2',
);
export const bottomLeftStyle = clsx(
  'after:wh-bottom-full after:wh-border-t-transparent after:wh-left-2 -wh-left-1.5',
);
export const bottomRightStyle = clsx(
  'after:wh-bottom-full after:wh-border-t-transparent after:wh-right-0 after:-wh-translate-x-1/2 -wh-right-1.5',
);
export const bottomCenterStyle = clsx(
  'after:wh-bottom-full after:wh-border-t-transparent after:wh-left-1/2 after:-wh-translate-x-1/2',
);

export const contentStyle = clsx(
  'wh-absolute',
  'wh-pointer-events-none',
  'wh-drop-shadow-3xl',
  'wh-text-details',
  'wh-leading-relaxed',
  'wh-shadow-one',
  'wh-whitespace-no-wrap',
  'wh-bg-white',
  'wh-rounded-md',
  'wh-p-3',
  'wh-z-1000',
  'wh-min-w-[318px]',
  'wh-block',
  'wh-opacity-0',
  'group-hover:wh-opacity-100',
  'wh-transition-all',
  'wh-duration-300',
  'after:wh-content-[""]',
  'after:wh-absolute',
  'after:wh-w-0',
  'after:wh-h-0',
  'after:wh-block',
  'after:wh-border-white',
  'after:wh-border-8',
  'after:wh-border-l-transparent',
  'after:wh-border-r-transparent',
);
