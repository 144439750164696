import clsx from 'clsx';
import { TextSizes } from '../../../../common/enums/TextSizes.enum';

export const BackDropBackGroundOverlay = clsx(
  'wh-flex',
  'wh-items-end',
  'wh-fixed',
  'wh-w-full',
  'wh-h-screen',
  'wh-bg-backdrop',
  'wh-inset-0',
  'wh-z-10000',
  'wh-overflow-x-hidden',
  'wh-overflow-y-hidden',
  'wh-outline-none',
  'focus:wh-outline-none',
  'wh-transition-all',
  'wh-duration-300',
  'wh-ease-in-out',
);
export const ModalContainer = clsx(
  'wh-w-full',
  'wh-relative',
  'wh-bg-white',
  'wh-animate-grow',
);

export const ModalContent = clsx(
  'wh-relative',
  'wh-flex',
  'wh-flex-col',
  'wh-outline-none',
  'focus:wh-outline-none',
  'wh-p-6',
  'wh-flex-auto',
);

export const GripStyle = clsx(
  'wh-flex',
  'wh-justify-content-center',
  'wh-mx-auto',
  '-wh-my-3',
  'wh-mb-6',
);
export const TitleStyle = clsx(`wh-text-${TextSizes.H4}`);
export const TextStyle = clsx(`wh-text-${TextSizes.BODY_1}`);
