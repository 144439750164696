import React, { FC } from 'react';
import clsx from 'clsx';
import { StepProps } from './Step.types';
import { StepStyle, StepNumberStyle } from './Step.styles';
import { Card } from '../../Cards/Card/Card';

/**
 * @deprecated This component will be removed from Whale in future versions
 */
export const Step: FC<StepProps> = ({
  className,
  number,
  header,
  body,
  footer,
}) => {
  return (
    <Card className={clsx(StepStyle, className)}>
      <div className={StepNumberStyle}>{number}</div>
      <div className="wh-step-header">{header}</div>
      <div className="wh-step-body">{body}</div>
      <div className="wh-step-footer">{footer}</div>
    </Card>
  );
};
