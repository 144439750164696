import React from 'react';
import clsx from 'clsx';
import { flexRender, Header, SortDirection } from '@tanstack/react-table';
import {
  headerContainerStyles,
  headerSortIconStyles,
  headerStyles,
  headerTitleStyles,
} from '../../WhDataGrid.styles';

const SORT_ICONS: Record<SortDirection, any> = {
  asc: 'increase',
  desc: 'decrease',
};

export type DataGridHeaderProps = {
  header: Header<unknown, unknown>;
};
export const DataGridHeader: React.FC<DataGridHeaderProps> = ({ header }) => {
  const sortDirection = header.column.getIsSorted() as SortDirection;

  return (
    <>
      <th
        className={clsx(headerStyles)}
        key={header.id}
        colSpan={header.colSpan}
        style={{
          width: header.getSize(),
        }}
      >
        <>
          {header.isPlaceholder ? null : (
            <div
              onMouseDown={header.getResizeHandler()}
              onTouchStart={header.getResizeHandler()}
              className={clsx(
                'resize',
                header.column.getIsResizing() ? 'isResizing' : '',
              )}
              onClick={header.column.getToggleSortingHandler()}
            >
              <div className={clsx(headerContainerStyles)}>
                <div className={clsx(headerTitleStyles)}>
                  <>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </>
                </div>
                <div className={clsx(headerSortIconStyles)}>
                  <DataGridHeaderSortIcon sort={sortDirection} />
                </div>
              </div>
            </div>
          )}
        </>
      </th>
    </>
  );
};

type DataGridHeaderSortIconProps = {
  sort: SortDirection;
};
const DataGridHeaderSortIcon: React.FC<DataGridHeaderSortIconProps> = ({
  sort,
}) => {
  const sortChild = SORT_ICONS[sort] || null;
  if (sortChild) return <i className={clsx(`wh-icon-${sortChild}`)}></i>;

  return null;
};
