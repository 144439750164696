import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  tabStyle,
  buttonGroupStyle,
  tabButtonStyle,
  tabButtonActiveStyle,
  tabDetailStyle,
  buttonStyle,
} from './WhTab.styles';
import { WhTabProps } from './WhTab.types';

export const WhTab: FC<WhTabProps> = ({
  items,
  defaultActiveKey,
  onChange,
}) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const tabChange = (key: string) => {
    if (key !== activeKey) {
      setActiveKey(key);
      if (onChange) onChange(key);
    }
  };
  const selectedItem = useMemo(
    () => items.find(({ key }) => key === activeKey),
    [items, activeKey],
  );

  return (
    <div className={tabStyle}>
      <div className={clsx(buttonGroupStyle)}>
        {items.map(({ key, label }) => (
          <button
            className={clsx(
              buttonStyle,
              key === activeKey ? tabButtonActiveStyle : tabButtonStyle,
            )}
            onClick={() => tabChange(key)}
            key={key}
          >
            {label}
          </button>
        ))}
      </div>
      <div className={clsx(tabDetailStyle)}>{selectedItem?.detail}</div>
      <div>{selectedItem?.children}</div>
    </div>
  );
};
