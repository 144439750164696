import React from 'react';
import { DataGridCardProps } from './DataGridCard.types';
import { Card } from '../../../Cards';
import { flexRender, RowData } from '@tanstack/react-table';
import {
  cardStyle,
  topCells,
  bottomCells,
  innerCells,
} from './DataGridCard.styles';

export const DataGridCard: React.FC<DataGridCardProps<RowData>> = ({
  cells,
  cardViewFields,
}) => {
  const cellViewFields = cardViewFields.map(col => {
    return cells.filter(cell =>
      col.some(item => item.field === cell.column.id),
    );
  });
  return (
    <Card className={cardStyle}>
      {cellViewFields.length > 0 && (
        <>
          <div className={topCells}>
            {cellViewFields[0].map((cell, index) => (
              <div key={index} className={innerCells}>
                <>
                  {flexRender(cell?.column.columnDef.cell, cell.getContext())}
                </>
              </div>
            ))}
          </div>
          <div className={bottomCells}>
            {cellViewFields[1].reverse().map((cell, index) => (
              <div key={index} className={innerCells}>
                <>
                  {flexRender(cell?.column.columnDef.cell, cell.getContext())}
                </>
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
};
