import React, { FC } from 'react';
import clsx from 'clsx';
import { TextBoxProps } from './TextBox.types';
import {
  commonStyle,
  contentStyle,
  setColor,
  setIconStyle,
} from './TextBox.styles';
import { Sizes } from '../../../common/enums/Sizes.enum';
import { States } from '../../../common/enums/States.enum';
import { Styles } from '../../../common/enums/Styles.enum';
import { getStateIcon } from '../../../common/utils';
import { Text } from '../../Text';
import { TextSizes } from '../../../common/enums/TextSizes.enum';

export const TextBox: FC<TextBoxProps> = ({
  className,
  size = Sizes.SMALL,
  state = States.SUCCESS,
  textBoxStyle = Styles.CONTAINED,
  icon = true,
  text,
}) => {
  return (
    <div
      className={clsx(className, commonStyle, setColor(state, textBoxStyle))}
    >
      <div className={contentStyle}>
        {icon && (
          <i className={clsx(getStateIcon(state), setIconStyle(state))} />
        )}
        {
          <Text
            size={
              size === Sizes.SMALL ? TextSizes.CHIP_SMALL : TextSizes.CHIP_BIG
            }
          >
            {text}
          </Text>
        }
      </div>
    </div>
  );
};
