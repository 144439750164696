import clsx from 'clsx';

export const tabStyle = clsx('wh-p-1');

export const buttonGroupStyle = clsx(
  'wh-p-1',
  'wh-flex',
  'wh-rounded-md',
  'wh-justify-start',
  'wh-mt-4',
  'wh-mx-0',
  'wh-mb-5',
  'wh-overflow-x-auto',
  'md:wh-overflow-x-scroll',
  'wh-bg-gray-15',
  'wh-flex-row',
);

const tabButtonCommonStyle = clsx(
  'wh-border-none',
  'wh-rounded-md',
  'wh-font-medium',
  'wh-h-7',
  'wh-transition wh-duration-10',
  'wh-w-auto',
  'wh-py-0',
  'wh-text-body1',
  'wh-text-secondary-50',
  'wh-p-0',
  'md:wh-p-auto',
  'even:wh-my-0',
);

export const tabButtonStyle = clsx(
  tabButtonCommonStyle,
  'wh-bg-gray-15',
  'wh-text-secondary-50',
  'hover:wh-bg-gray-15',
);
export const buttonStyle = clsx('wh-grow');
export const tabButtonActiveStyle = clsx(
  tabButtonCommonStyle,
  'wh-bg-white',
  'wh-text-secondary-main',
  'hover:wh-bg-white',
);

export const tabDetailStyle = clsx('wh-text-secondary-50', 'wh-mb-6');
