import React from 'react';
import { HeaderGroup } from '@tanstack/react-table';
import { DataGridHeader } from '../DataGridHeader';

export type DataGridHeaderGroupProps = {
  group: HeaderGroup<unknown>;
};
export const DataGridHeaderGroup: React.FC<DataGridHeaderGroupProps> = ({
  group,
}) => {
  return (
    <>
      <tr key={group.id}>
        {group.headers.map(header => (
          <DataGridHeader key={header.id} header={header} />
        ))}
      </tr>
    </>
  );
};
