import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React from 'react';
import { FontWeight, TextSize, Text } from '../../../Text';
import { DataGridCellBaseProps } from '../../DataGridCell.types';

export type DataGridDateCellProps = {
  size?: TextSize;
  weight?: FontWeight;
};
export const DataGridDateCell: React.FC<
  DataGridCellBaseProps<Date | string | Dayjs, DataGridDateCellProps>
> = ({ value, cellProps }) => {
  const date = dayjs(value);

  let dateString = '-';

  if (date.isValid()) dateString = date.format('DD MMM YYYY');

  return (
    <Text
      className="wh-text-secondary-50"
      weight="regular"
      size="body1"
      {...cellProps}
    >
      {dateString}
    </Text>
  );
};
