import clsx from 'clsx';
import { Sizes } from '../../../common/enums/Sizes.enum';
import { TextSizes } from '../../../common/enums/TextSizes.enum';
import { LinkTypes } from './Link.enums';

export const baseStyle = clsx('wh-flex', 'wh-items-center');

const commonIconClasses = clsx(`wh-text-${TextSizes.H2_MOBILE}`);

export const iconStylePrimary = clsx(
  commonIconClasses,
  'wh-text-primary-main',
  'hover:wh-text-primary-75',
  'active:wh-text-primary-75',
  'disabled:hover:wh-bg-primary-25',
);

export const iconStyleSecondary = clsx(
  commonIconClasses,
  'wh-text-secondary-main',
  'hover:wh-text-secondary-dark',
  'active:wh-text-secondary-dark',
  'disabled:hover:wh-bg-secondary-25',
);

export const sizeStyles = {
  [Sizes.BIG]: clsx(`wh-text-${TextSizes.LINK_BIG}`),
  [Sizes.MEDIUM]: clsx(`wh-text-${TextSizes.LINK_MEDIUM}`),
  [Sizes.SMALL]: clsx(`wh-text-${TextSizes.LINK_SMALL}`),
};

const commonTypeStyles = clsx('wh-cursor-pointer');

export const typeStyles = {
  [LinkTypes.PRIMARY]: clsx(
    commonTypeStyles,
    'wh-text-primary-main',
    'hover:wh-text-primary-75',
    'active:wh-text-primary-75',
  ),
  [LinkTypes.SECONDARY]: clsx(
    commonTypeStyles,
    'wh-text-secondary-main',
    'hover:wh-text-secondary-dark',
    'active:wh-text-secondary-dark',
  ),
  [LinkTypes.NEGATIVE]: clsx(
    commonTypeStyles,
    'wh-text-error-main',
    'hover:wh-text-error-75',
    'active:wh-text-error-75',
  ),
};

export const iconTypeStyles = {
  [LinkTypes.PRIMARY]: clsx(commonIconClasses),
  [LinkTypes.SECONDARY]: clsx(commonIconClasses),
  [LinkTypes.NEGATIVE]: clsx(commonIconClasses),
};

const disabledCommonStyles = clsx('wh-cursor-not-allowed');

export const disabledStyles = {
  [LinkTypes.PRIMARY]: clsx(disabledCommonStyles, 'wh-text-primary-25'),
  [LinkTypes.SECONDARY]: clsx(disabledCommonStyles, 'wh-text-secondary-25'),
  [LinkTypes.NEGATIVE]: clsx(disabledCommonStyles, 'wh-text-error-25'),
};
