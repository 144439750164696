import React from 'react';

export const DataGridErrorImg = () => (
  <svg
    width="124"
    height="124"
    viewBox="0 0 168 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="84" cy="84" r="84" fill="#F9F9F9" />
    <g filter="url(#filter0_d_2026_1001)">
      <path
        d="M65.3896 107.505H42.1054C40.8494 107.505 39.7169 108.262 39.2364 109.422C38.7559 110.582 39.0215 111.918 39.9097 112.806L63.1938 136.091C64.082 136.979 65.4178 137.244 66.5782 136.764C67.7386 136.283 68.4953 135.151 68.4953 133.895V110.61C68.4951 108.895 67.1047 107.505 65.3896 107.505Z"
        fill="#C4CDD5"
      />
    </g>
    <g filter="url(#filter1_d_2026_1001)">
      <path
        d="M108.631 137H65.3897C63.6746 137 62.2842 135.61 62.2842 133.895V116.821C62.2842 115.106 60.8938 113.716 59.1787 113.716H42.1055C40.3904 113.716 39 112.325 39 110.61V34.1055C39 32.3904 40.3904 31 42.1055 31H108.631C110.346 31 111.737 32.3904 111.737 34.1055V133.895C111.737 135.61 110.346 137 108.631 137Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_d_2026_1001)">
      <path
        d="M108.632 31H75.3687V137H108.632C110.347 137 111.737 135.61 111.737 133.895V34.1055C111.737 32.3904 110.347 31 108.632 31Z"
        fill="white"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M54.4545 57.0132L49.1138 53.8857C48.5539 53.5579 48.3686 52.8441 48.6998 52.2916C49.031 51.739 49.7534 51.5569 50.3133 51.8848L55.6541 55.0122C56.214 55.3401 56.3993 56.0538 56.0681 56.6063C55.7368 57.1589 55.0144 57.341 54.4545 57.0132Z"
        fill="url(#paint0_linear_2026_1001)"
      />
      <path
        d="M59.9063 50.8201L56.2994 45.7907C55.9212 45.2635 56.0422 44.5361 56.5695 44.166C57.0969 43.796 57.8309 43.9234 58.209 44.4506L61.816 49.48C62.1941 50.0072 62.0732 50.7346 61.5458 51.1047C61.0185 51.4748 60.2844 51.3473 59.9063 50.8201Z"
        fill="url(#paint1_linear_2026_1001)"
      />
      <path
        d="M52.9824 64.2036L46.7935 64.1655C46.1446 64.1615 45.6273 63.6361 45.6378 62.9919C45.6484 62.3478 46.183 61.8288 46.8318 61.8328L53.0207 61.8709C53.6695 61.8749 54.1869 62.4003 54.1763 63.0445C54.1658 63.6886 53.6312 64.2076 52.9824 64.2036Z"
        fill="url(#paint2_linear_2026_1001)"
      />
    </g>
    <path
      d="M121.726 107.015L121.754 106.987L136.962 122.283C138.763 124.084 138.763 127.003 136.962 128.803C135.43 130.335 133.088 130.564 131.314 129.488C130.955 129.286 130.617 129.033 130.312 128.727L105.427 103.7L111.947 97.1798L121.726 107.015Z"
      fill="#42A2BE"
    />
    <path
      d="M117.58 79.6976C117.58 95.7877 104.536 108.831 88.4462 108.831C72.3561 108.831 59.3125 95.7877 59.3125 79.6976C59.3125 63.6076 72.3561 50.564 88.4462 50.564C104.536 50.564 117.58 63.6076 117.58 79.6976Z"
      stroke="#C0E0E9"
      stroke-width="8"
    />
    <path
      d="M121.58 79.6988C121.58 97.9972 106.745 112.832 88.4469 112.832C87.0632 112.832 85.702 112.747 84.3635 112.581C100.734 110.569 113.41 96.6132 113.41 79.6985C113.41 62.781 100.734 48.8256 84.3606 46.8134C85.6994 46.6472 87.0632 46.5625 88.4469 46.5625C106.745 46.5628 121.58 61.3976 121.58 79.6988Z"
      fill="#80C1D3"
    />
    <path
      d="M88.5 104C102.031 104 113 93.031 113 79.5C113 65.969 102.031 55 88.5 55C74.969 55 64 65.969 64 79.5C64 93.031 74.969 104 88.5 104Z"
      fill="white"
    />
    <path
      d="M90.6957 65L90.1739 86.6578H85.6957L85.1522 65H90.6957ZM85 93.3007C85 92.5274 85.2609 91.8807 85.7826 91.3605C86.3188 90.8263 87.058 90.5592 88 90.5592C88.9275 90.5592 89.6594 90.8263 90.1957 91.3605C90.7319 91.8807 91 92.5274 91 93.3007C91 94.0458 90.7319 94.6855 90.1957 95.2197C89.6594 95.7399 88.9275 96 88 96C87.058 96 86.3188 95.7399 85.7826 95.2197C85.2609 94.6855 85 94.0458 85 93.3007Z"
      fill="url(#paint3_linear_2026_1001)"
    />
    <defs>
      <filter
        id="filter0_d_2026_1001"
        x="26.8719"
        y="93.2366"
        width="53.7515"
        height="53.7514"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2.14025" />
        <feGaussianBlur stdDeviation="6.06403" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.108708 0 0 0 0 0.237823 0 0 0 0 0.391768 0 0 0 0.0690104 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2026_1001"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2026_1001"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2026_1001"
        x="22.8719"
        y="20.8719"
        width="96.9929"
        height="130.256"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="2" />
        <feGaussianBlur stdDeviation="6.06403" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.108708 0 0 0 0 0.237823 0 0 0 0 0.391768 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2026_1001"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2026_1001"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2026_1001"
        x="60.3687"
        y="15"
        width="62.3687"
        height="132"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="-3" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.108708 0 0 0 0 0.237823 0 0 0 0 0.391768 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2026_1001"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2026_1001"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2026_1001"
        x1="48.5979"
        y1="51.6616"
        x2="56.4406"
        y2="52.0726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8FA1B0" />
        <stop offset="1" stop-color="#5F7083" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2026_1001"
        x1="56.7327"
        y1="43.5491"
        x2="63.7301"
        y2="47.1145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8FA1B0" />
        <stop offset="1" stop-color="#5F7083" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2026_1001"
        x1="45.2346"
        y1="62.4973"
        x2="52.2321"
        y2="58.9319"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8FA1B0" />
        <stop offset="1" stop-color="#5F7083" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2026_1001"
        x1="85"
        y1="65"
        x2="86.5"
        y2="121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8FA1B0" />
        <stop offset="1" stop-color="#5F7083" />
      </linearGradient>
    </defs>
  </svg>
);
