import React, { FC } from 'react';
import clsx from 'clsx';
import { LinkProps, LinkType } from './Link.types';
import {
  baseStyle,
  disabledStyles,
  iconTypeStyles,
  sizeStyles,
  typeStyles,
} from './Link.styles';
import { LinkTypes } from './Link.enums';
import { IconPositions } from '../../../common/enums/IconPositions.enum';
import { Sizes } from '../../../common/enums/Sizes.enum';
import { preventDefaultFn } from '../../../common/utils';

const renderIcon = (icon: string, type: LinkType, margin: string) => (
  <i
    className={clsx(`wh-icon wh-icon-${icon}`, iconTypeStyles[type], margin)}
  />
);

export const Link: FC<LinkProps> = ({
  className,
  children,
  linkType = LinkTypes.PRIMARY,
  icon,
  iconPosition = IconPositions.RIGHT,
  size = Sizes.MEDIUM,
  disabled,
  onClick,
  ...props
}: LinkProps) => (
  <a
    className={clsx(
      className,
      baseStyle,
      disabled ? disabledStyles[linkType] : typeStyles[linkType],
      sizeStyles[size],
    )}
    onClick={disabled ? preventDefaultFn : onClick}
    {...props}
  >
    {icon &&
      iconPosition === IconPositions.LEFT &&
      renderIcon(icon, linkType, 'wh-mr-1')}
    {children}
    {icon &&
      iconPosition === IconPositions.RIGHT &&
      renderIcon(icon, linkType, 'wh-ml-1')}
  </a>
);
