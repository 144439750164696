import React, { useState } from 'react';
import { RowModel, Row } from '@tanstack/react-table';
import { DataGridRow } from '../DataGridRow';
import { DataGridRowMobile } from '../DataGridRowMobile';
import { MobileViewContent } from '../../WhDataGrid.types';
import { DataGridRowModalMobile } from '../DataGridRowModalMobile';

export type DataGridRowModelProps = {
  rowModel: RowModel<unknown>;
  mobileViewContent: MobileViewContent;
};
export const DataGridRowModel: React.FC<DataGridRowModelProps> = ({
  rowModel,
  mobileViewContent,
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);

  const [modalData, setModalData] = useState<Row<unknown> | null>(null);

  const toggleModal = (row: Row<unknown>) => {
    setIsModal(!isModal);
    setModalData(row);
  };

  return (
    <>
      {rowModel.rows.map(row => (
        <React.Fragment key={row.id}>
          <DataGridRow row={row} />
          <DataGridRowMobile
            row={row}
            mobileViewContent={mobileViewContent}
            toggleModal={() => toggleModal(row)}
          />
        </React.Fragment>
      ))}
      {modalData && (
        <DataGridRowModalMobile
          row={modalData}
          mobileViewContent={mobileViewContent}
          toggleModal={() => toggleModal(modalData)}
          isOpen={isModal}
        />
      )}
    </>
  );
};
