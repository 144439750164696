import React from 'react';
import { Row } from '@tanstack/react-table';
import { MobileViewContent } from '../../WhDataGrid.types';
import { DataGridModalCard } from '../DataGridModalCard/DataGridModalCard';
import { WhModal, WhModalDescription, WhModalTitle } from '../../../WhModal';
import { showOnMobile } from './DataGridRowModalMobile.styles';

export type DataGridRowModalMobileProps = {
  row: Row<unknown>;
  mobileViewContent: MobileViewContent;
  toggleModal: () => void | Promise<void>;
  isOpen: boolean;
};
export const DataGridRowModalMobile: React.FC<DataGridRowModalMobileProps> = ({
  row,
  mobileViewContent,
  toggleModal,
  isOpen,
}) => {
  return (
    <WhModal open={isOpen} className={showOnMobile} onClose={toggleModal}>
      <WhModalTitle onClose={toggleModal}>
        {mobileViewContent.modalTitle}
      </WhModalTitle>
      <WhModalDescription>
        <DataGridModalCard
          cells={row.getVisibleCells()}
          mobileViewContent={mobileViewContent}
        />
      </WhModalDescription>
    </WhModal>
  );
};
