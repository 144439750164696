import React, { FC } from 'react';
import clsx from 'clsx';
import { ChipProps } from '../Chip.types';
import { chipLabelStyle, chipStyle, setChipType } from '../Chip.styles';

export const StatusChip: FC<ChipProps> = ({ children, chipType }) => {
  return (
    <div className={chipStyle}>
      <span
        className={clsx(chipLabelStyle, setChipType(chipType ?? 'success'))}
      >
        {children}
      </span>
    </div>
  );
};
