import React from 'react';
import { FontWeight, TextSize, Text } from '../../../Text';
import { DataGridCellBaseProps } from '../../DataGridCell.types';

export type DataGridTextCellProps = {
  size?: TextSize;
  weight?: FontWeight;
};
export const DataGridTextCell: React.FC<
  DataGridCellBaseProps<string, DataGridTextCellProps>
> = ({ value, cellProps }) => {
  return (
    <Text size="body1" {...cellProps}>
      {value}
    </Text>
  );
};
