import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
  SnackbarButtonPositions,
  SnackbarSizes,
  SnackbarBackgrounds,
} from './Snackbar.enums';
import { SnackbarProperties } from './Snackbar.types';
import {
  bigSizeStyle,
  blackContainerStyle,
  buttonBottomStyle,
  buttonRightStyle,
  closeButtonStyle,
  closeWithSnackbarButtonStyle,
  commonStyle,
  dividerStyle,
  exitStyle,
  flexColumn,
  leftIconStyle,
  messageStyle,
  providerStyle,
  redContainerStyle,
  smallSizeStyle,
  SnackbarButtonStyle,
  snackbarHeader,
} from './Snackbar.styles';
import { Toaster } from 'react-hot-toast';

export const SnackbarContainer = ({
  message,
  background,
  size,
  buttonPosition,
  actionDisabled,
  buttonText,
  closeSnackbar,
  duration,
  handleButtonClick,
  closeSnackbarAfterButtonClick,
  closeSnackbarWithClickAnywhere,
  iconName,
}: SnackbarProperties) => {
  const handleButtonClickWrapped = useCallback(() => {
    if (handleButtonClick) {
      handleButtonClick();
      if (closeSnackbarAfterButtonClick) {
        closeSnackbar();
      }
    }
  }, [closeSnackbar, closeSnackbarAfterButtonClick, handleButtonClick]);

  return (
    <div
      className={clsx(
        commonStyle,
        [
          background === SnackbarBackgrounds.BLACK && blackContainerStyle,
          background === SnackbarBackgrounds.RED && redContainerStyle,
        ],
        [
          size === SnackbarSizes.SMALL && smallSizeStyle,
          size === SnackbarSizes.BIG && bigSizeStyle,
        ],
        [buttonPosition === SnackbarButtonPositions.BOTTOM && flexColumn],
      )}
      onClick={closeSnackbarWithClickAnywhere ? closeSnackbar : undefined}
    >
      <div id="snackbarHeader" className={snackbarHeader}>
        <i className={clsx(leftIconStyle, iconName)}></i>
        <div className={messageStyle}>{message}</div>
        {buttonPosition === SnackbarButtonPositions.RIGHT && (
          <button
            onClick={handleButtonClickWrapped}
            className={clsx(SnackbarButtonStyle, buttonRightStyle)}
            disabled={actionDisabled}
          >
            {buttonText}
          </button>
        )}
        {[undefined, 0].includes(duration) && (
          <i
            className={clsx(closeButtonStyle, [
              buttonPosition === SnackbarButtonPositions.RIGHT &&
                closeWithSnackbarButtonStyle,
            ])}
          >
            <i className={dividerStyle} onClick={closeSnackbar}></i>
            <i className={exitStyle} onClick={closeSnackbar}></i>
          </i>
        )}
      </div>
      {buttonPosition === SnackbarButtonPositions.BOTTOM && (
        <button
          onClick={handleButtonClickWrapped}
          className={clsx(SnackbarButtonStyle, buttonBottomStyle)}
          disabled={actionDisabled}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export const SnackbarProvider = () => (
  <Toaster
    toastOptions={{
      className: providerStyle,
    }}
  />
);
