import clsx from 'clsx';
import { FontWeights } from '../../../common/enums/FontWeights.enum';
import { TextSizes } from '../../../common/enums/TextSizes.enum';
import { TickerSizeEnum, TickerVariantEnum } from './WhTickerInput.enum';
import { TickerSize, TickerVariant } from './WhTickerInput.types';

const wrapperStyles = clsx('wh-flex', 'wh-flex-col');

const cardVariantStyles = {
  [TickerVariantEnum.PRIMARY]: {
    background: 'wh-bg-gray-5 aria-disabled:wh-bg-gray-15',
    border: 'wh-border-gray-15 focus-within:wh-border-primary-main',
  },
  [TickerVariantEnum.SECONDARY]: {
    background: 'wh-bg-secondary-dark',
    border: 'wh-border-secondary-dark focus-within:wh-border-primary-main',
  },
} as const;

const cardSizeStyles = {
  [TickerSizeEnum.BIG]: {
    padding: {
      top: ['wh-pt-7', 'wh-pt-3'],
      bottom: ['wh-pb-7', 'wh-pb-3'],
    },
    minHeight: 'wh-min-h-[5.5rem]',
  },
  [TickerSizeEnum.MEDIUM]: {
    padding: {
      top: ['wh-pt-5', 'wh-pt-3'],
      bottom: ['wh-pb-5', 'wh-pb-3'],
    },
    minHeight: 'wh-min-h-[4.5rem]',
  },
} as const;

const getCardStyles = (variant: TickerVariant, size: TickerSize) => {
  return (hasError: boolean, hasFooter: boolean) => {
    const paddingBottomOption = Number(hasFooter);
    const paddingTopOption = Number(hasError);

    return clsx(
      cardVariantStyles[variant].background,
      cardVariantStyles[variant].border,
      'wh-rounded-md',
      'wh-border',
      'wh-px-4',
      cardSizeStyles[size].padding.bottom[paddingBottomOption],
      cardSizeStyles[size].padding.top[paddingTopOption],
      'wh-flex',
      'wh-flex-col',
      'wh-gap-1',
      'wh-justify-start',
      cardSizeStyles[size]['minHeight'],
    );
  };
};

const labelStyles = clsx(
  `wh-text-${TextSizes.LABEL}`,
  `wh-font-${FontWeights.REGULAR}`,
  'wh-text-secondary-main',
  'wh-mb-1.5',
  'wh-flex',
  'wh-items-center',
);

const messageErrorStyles = clsx(
  'wh-text-error-dark',
  'wh-text-helper-text',
  'wh-min-h-[0.75rem]',
);

const bodyStyles = clsx(
  'wh-flex',
  'wh-justify-between',
  'wh-items-center',
  'wh-relative',
);

const getInputStyles = (variant: TickerVariant) => {
  const variantInputTextColor =
    variant === 'PRIMARY'
      ? 'wh-text-secondary-main disabled:wh-text-secondary-25'
      : 'wh-text-white disabled:wh-text-secondary-50';

  return clsx(
    'wh-bg-transparent',
    'wh-text-numbers-small',
    'focus:wh-outline-none',
    'wh-input-number-arrow-disabled',
    'wh-min-w-[6rem]',
    'wh-caret-primary-main',
    variantInputTextColor,
  );
};

const getSelectedTickerValueStyles = (hasValues: boolean) =>
  clsx(
    'wh-flex',
    'wh-items-center',
    'wh-gap-1.5',
    !hasValues && 'wh-cursor-default',
    'disabled:wh-text-secondary-25',
  );

const getSelectedTickerIconStyles = (disabled: boolean) =>
  clsx('wh-w-5', 'wh-h-5', disabled && 'wh-opacity-50');

const getSelectedTickerLabelStyles = (variant: TickerVariant) => {
  const disabledStyles = {
    [TickerVariantEnum.PRIMARY]: [
      'wh-text-secondary-main',
      'wh-text-secondary-25',
    ],
    [TickerVariantEnum.SECONDARY]: ['wh-text-white', 'wh-text-secondary-50'],
  } as const;

  return (disabled: boolean) => {
    const disabledStyleOption = Number(disabled);

    return clsx(
      `wh-text-${TextSizes.BODY_1}`,
      `wh-font-${FontWeights.MEDIUM}`,
      disabledStyles[variant][disabledStyleOption],
    );
  };
};

const getTickerOptionsContainerStyles = (showMenu: boolean) =>
  clsx(
    'wh-flex',
    showMenu ? 'wh-flex-col' : 'wh-hidden',
    'wh-absolute',
    'wh-z-[10]',
    'wh-right-0',
    'wh-bg-white',
    'wh-py-2',
    'wh-rounded-md',
    'wh-shadow-one',
    'wh-top-full',
    'wh-mt-1',
    'wh-min-w-[6.25rem]',
  );

const tickerOptionWrapperStyles = clsx(
  'wh-py-2',
  'wh-px-4',
  'wh-gap-1',
  'wh-flex',
  `wh-text-${TextSizes.BODY_2}`,
  `wh-font-${FontWeights.REGULAR}`,
  'hover:wh-bg-gray-15',
  'wh-cursor-pointer',
  'wh-text-secondary-main',
);

const tickerOptionDividerStyles = clsx(
  'wh-h-0.5',
  'wh-mx-4',
  'wh-bg-neutral-light',
  'wh-rounded-sm',
  'last:wh-hidden',
);

const tickerOptionIconStyles = clsx('wh-w-6', 'wh-h-6');

const footerStyles = clsx(
  'wh-flex',
  'wh-items-center',
  'wh-justify-end',
  'wh-w-full',
  'wh-text-right',
  `wh-text-${TextSizes.DETAILS}`,
  `wh-font-${FontWeights.LIGHT}`,
  'wh-min-h-[1.5rem]',
  'wh-text-neutral-dark',
);

export const getTickerStyles = (variant: TickerVariant, size: TickerSize) => {
  return {
    cardStyles: getCardStyles(variant, size),
    inputStyles: getInputStyles(variant),
    selectedTickerStyles: {
      value: getSelectedTickerValueStyles,
      icon: getSelectedTickerIconStyles,
      label: getSelectedTickerLabelStyles(variant),
    },
    tickerOption: {
      container: getTickerOptionsContainerStyles,
      wrapper: tickerOptionWrapperStyles,
      divider: tickerOptionDividerStyles,
      icon: tickerOptionIconStyles,
    },
    footerStyles,
    wrapperStyles,
    labelStyles,
    messageErrorStyles,
    bodyStyles,
  };
};
