export enum TextSizes {
  HELPER_TEXT = 'helper-text',
  INPUT_TEXT = 'input-text',
  LABEL = 'label',
  CHIP_SMALL = 'chip-small',
  CHIP_BIG = 'chip-big',
  NAVIGATION = 'navigation',
  NUMBERS_EXTRA_SMALL = 'numbers-extra-small',
  LINK_SMALL = 'link-small',
  LINK_MEDIUM = 'link-medium',
  LINK_BIG = 'link-big',
  BUTTON_SMALL = 'button-small',
  BUTTON_BIG = 'button-big',
  DETAILS = 'details',
  BODY_2 = 'body2',
  BODY_1 = 'body1',
  H5 = 'h5',
  H4 = 'h4',
  H3 = 'h3',
  H3_MOBILE = 'h3-mobile',
  NUMBERS_SMALL = 'numbers-small',
  H3_DESKTOP = 'h3-desktop',
  H2 = 'h2',
  H2_MOBILE = 'h2-mobile',
  H2_DESKTOP = 'h2-desktop',
  NUMBERS_MEDIUM = 'numbers-medium',
  H1 = 'h1',
  H1_MOBILE = 'h1-mobile',
  H1_DESKTOP = 'h1-desktop',
  NUMBERS_BIG = 'numbers-big',
  NUMBERS_EXTRA_BIG = 'numbers-extra-big',
  NONE = 'none',
}
