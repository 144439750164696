import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { SnackbarContainer } from './Snackbar';
import { SnackbarProperties } from './Snackbar.types';

type SnackbarAddedProperties = Omit<SnackbarProperties, 'closeSnackbar'> & {
  mobile?: boolean;
};

export const useSnackbar = (
  props: SnackbarAddedProperties,
): [() => void, () => void] => {
  const longWaitTime = 9999999999;
  const dismissCallback = useCallback(() => toast.dismiss(), []);

  const openSnackbar = () => {
    toast(<SnackbarContainer {...props} closeSnackbar={dismissCallback} />, {
      position: props.mobile ? 'top-center' : 'bottom-center',
      duration: props.duration ? props.duration : longWaitTime,
      style: { background: 'transparent' },
    });
  };
  return [openSnackbar, dismissCallback];
};
