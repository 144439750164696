import React from 'react';
import { BackDropProps } from './BackDrop.types';
import {
  BackDropBackGroundOverlay,
  ModalContainer,
  ModalContent,
  GripStyle,
  TitleStyle,
  TextStyle,
} from './BackDrop.styles';
import { GripSvg } from './GripIcon';

export const BackDrop = ({
  isOpen = false,
  handleBackDropClose,
  title = '',
  text,
}: BackDropProps) => {
  if (isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      {isOpen ? (
        <div
          id="backdropOverlay"
          onClick={handleBackDropClose}
          className={BackDropBackGroundOverlay}
        >
          <div
            style={{
              borderTopLeftRadius: '0.75rem',
              borderTopRightRadius: '0.75rem',
            }}
            className={ModalContainer}
          >
            <div className={ModalContent}>
              <div className={GripStyle}>
                <GripSvg />
              </div>
              <div className={TitleStyle}>{title}</div>
              <div className={TextStyle}>{text}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BackDrop;
