import React from 'react';
import {
  DataGridCellTypes,
  DataGridCellBaseProps,
} from '../../DataGridCell.types';
import {
  DataGridAmountCell,
  DataGridAssetCell,
  DataGridPercentageCell,
  DataGridTagCell,
  DataGridTextCell,
  DataGridDateCell,
  DataGridHeadingCell,
} from '../../cells';

const Cells: Record<DataGridCellTypes, Function> = {
  AMOUNT: DataGridAmountCell,
  ASSET: DataGridAssetCell,
  TAG: DataGridTagCell,
  TEXT: DataGridTextCell,
  HEADING: DataGridHeadingCell,
  PERCENTAGE: DataGridPercentageCell,
  DATE: DataGridDateCell,
};

const DefaultCell = Cells['TEXT'];

export type DataGridCellWrapperProps = {
  type?: DataGridCellTypes;
} & DataGridCellBaseProps;
export const DataGridCellWrapper: React.FC<DataGridCellWrapperProps> = ({
  type,
  data,
  ...props
}) => {
  if (type) {
    const Cell = Cells[type];

    if (Cell) return <Cell data={data} {...props} />;
  }

  return <DefaultCell data={data} {...props} />;
};
