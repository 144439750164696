import React from 'react';

export const GripSvg = () => (
  <svg
    width="48"
    height="4"
    viewBox="0 0 48 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="4" rx="2" fill="#C2C5C9" />
  </svg>
);
