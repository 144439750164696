export enum SnackbarBackgrounds {
  RED = 'RED',
  BLACK = 'BLACK',
}

export enum SnackbarSizes {
  SMALL = 'SMALL',
  BIG = 'BIG',
}

export enum SnackbarButtonPositions {
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
  NONE = 'NONE',
}

export enum SnackbarBehaviourTypes {
  DURATION = 'DURATION',
  CLOSE_BUTTON = 'CLOSE_BUTTON',
}
