import clsx from 'clsx';

export const cellPositive = clsx('wh-text-success-dark');

export const cellNegative = clsx('wh-text-error-dark');

export const cellDefault = clsx('wh-text-secondary-main');

export const percentageCell = clsx('wh-font-medium');

export const iconStyle = clsx('wh-mr-1');
