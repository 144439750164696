import clsx from 'clsx';

const defaultClassName = clsx('wh-relative', 'wh-pt-4', 'wh-pb-4');
const defaultRailClassName = clsx(
  'wh-bg-gray-15',
  'wh-h-1',
  'wh-w-full',
  'wh-absolute',
  '-wh-mt-0.5',
  'wh-rounded-full',
);

const defaultTrackClassName = clsx(
  'wh-bg-primary-main',
  'wh-h-1',
  'wh-absolute',
  '-wh-mt-0.5',
  'wh-rounded-full',
);

const defaultHandleClassName = clsx(
  'wh-bg-white',
  'wh-h-6',
  'wh-w-6',
  '-wh-mt-3',
  'wh-rounded-full',
  'wh-absolute',
  'wh-border-none',
  'wh-shadow-zero',
  'wh-cursor-pointer',
);

const defaultMarksClassName = clsx('wh-mt-4');
const defaultMarkTextClassName = clsx(
  'wh-absolute',
  'wh-text-details',
  'wh-font-light',
  'wh-text-secondary-50',
);

export {
  defaultClassName,
  defaultHandleClassName,
  defaultMarkTextClassName,
  defaultMarksClassName,
  defaultRailClassName,
  defaultTrackClassName,
};
