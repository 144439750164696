import { MouseEventHandler } from 'react';
import { States } from './enums/States.enum';

const iconsByState = {
  [States.SUCCESS]: 'check',
  [States.PENDING]: 'timeless',
  [States.ERROR]: 'error',
  [States.INFO]: 'info',
  [States.NEUTRAL]: 'info',
  [States.WARNING]: 'warning',
};

export const getStateIcon = (state: States) =>
  `wh-icon wh-icon-${iconsByState[state]}`;

export const preventDefaultFn: MouseEventHandler = event =>
  event.preventDefault();
