import React from 'react';
import { DataGridCellBaseProps } from '../../DataGridCell.types';

export type DataGridAssetCellProps = {
  assetNameField: NonNullable<string>;
  iconField?: string;
};
export const DataGridAssetCell: React.FC<
  DataGridCellBaseProps<never, DataGridAssetCellProps>
> = ({ data, cellProps }) => {
  const { assetNameField, iconField } = cellProps;

  const assetName = data[assetNameField] || null;
  const icon = (iconField && data[iconField]) || null;

  return (
    <>
      <div>
        <span>assetName: {assetName}</span>
        <span>icon: {icon}</span>
      </div>
    </>
  );
};
