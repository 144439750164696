import React, { FC } from 'react';
import clsx from 'clsx';
import { StepListProps } from './StepList.types';
import { StepListStyle } from './StepList.styles';
import { Step } from '../Step/Step';
import { StepProps } from '../Step/Step.types';

/**
 * @deprecated This component will be removed from Whale in future versions
 */
export const StepList: FC<StepListProps> = ({ className, title, steps }) => {
  return (
    <div className={clsx(className, StepListStyle)}>
      <h3>{title}</h3>
      {steps.map((stepProp: StepProps) => (
        <Step {...stepProp} />
      ))}
    </div>
  );
};
