import clsx from 'clsx';
import { TextSizes } from '../../../common/enums/TextSizes.enum';
import { SpinnerSizes, SpinnerTypes } from '../../Icons/Spinner/Spinner.enums';
import { ButtonSizes, ButtonTypes, ButtonVariants } from './Button.enums';

export const commonStyle = clsx(
  'wh-relative',
  'wh-flex',
  'wh-justify-center',
  'wh-items-center',
  'wh-border',
  'wh-rounded-100',
  'wh-font-medium',
  'disabled:wh-cursor-not-allowed',
);

export const sizeStyles = {
  [ButtonSizes.SMALL]: clsx(
    'wh-h-9',
    'wh-py-2.25',
    `wh-text-${TextSizes.BUTTON_SMALL}`,
  ),
  [ButtonSizes.MEDIUM]: clsx(
    'wh-h-11',
    'wh-py-2.25',
    `wh-text-${TextSizes.BUTTON_BIG}`,
  ),
  [ButtonSizes.BIG]: clsx(
    'wh-h-13',
    'wh-py-3.25',
    `wh-text-${TextSizes.BUTTON_BIG}`,
  ),
};

export const iconSizeStyles = {
  [ButtonSizes.SMALL]: clsx('wh-text-base'),
  [ButtonSizes.MEDIUM]: clsx('wh-text-2xl'),
  [ButtonSizes.BIG]: clsx('wh-text-2xl'),
};

export const typeStyles = {
  [ButtonTypes.PRIMARY]: {
    [ButtonVariants.CONTAINED]: clsx(
      'wh-px-6',
      'wh-bg-primary-main',
      'wh-text-white',
      'wh-border-0',
      'hover:wh-bg-primary-75',
      'active:wh-bg-primary-75',
      'disabled:wh-bg-primary-25',
      'disabled:hover:wh-bg-primary-25',
    ),
    [ButtonVariants.OUTLINED]: clsx(
      'wh-px-6',
      'wh-text-primary-main',
      'wh-border-primary-main',
      'hover:wh-text-primary-75',
      'hover:wh-border-primary-75',
      'hover:wh-bg-primary-light',
      'active:wh-text-primary-75',
      'active:wh-border-primary-75',
      'active:wh-bg-primary-light',
      'disabled:wh-text-primary-25',
      'disabled:wh-border-primary-25',
      'disabled:hover:wh-text-primary-25',
      'disabled:hover:wh-border-primary-25',
      'disabled:hover:wh-bg-white',
    ),
  },
  [ButtonTypes.SECONDARY]: {
    [ButtonVariants.CONTAINED]: clsx(
      'wh-px-6',
      'wh-bg-secondary-main',
      'wh-text-white',
      'wh-border-0',
      'hover:wh-bg-secondary-50',
      'active:wh-bg-secondary-50',
      'disabled:wh-bg-neutral-light',
      'disabled:wh-text-secondary-25',
      'disabled:hover:wh-bg-neutral-light',
    ),
    [ButtonVariants.OUTLINED]: clsx(
      'wh-px-6',
      'wh-text-secondary-main',
      'wh-border-secondary-main',
      'hover:wh-text-secondary-50',
      'hover:wh-bg-gray-15',
      'hover:wh-border-secondary-50',
      'active:wh-text-secondary-50',
      'active:wh-bg-gray-15',
      'active:wh-border-secondary-50',
      'disabled:wh-text-secondary-25',
      'disabled:wh-border-secondary-25',
      'disabled:hover:wh-text-secondary-25',
      'disabled:hover:wh-border-secondary-25',
      'disabled:hover:wh-bg-white',
    ),
  },
  [ButtonTypes.NEGATIVE]: {
    [ButtonVariants.CONTAINED]: clsx(
      'wh-px-6',
      'wh-bg-error-main',
      'wh-text-white',
      'wh-border-0',
      'hover:wh-bg-error-75',
      'active:wh-bg-error-75',
      'disabled:wh-bg-error-25',
      'disabled:hover:wh-bg-error-25',
    ),
    [ButtonVariants.OUTLINED]: clsx(
      'wh-px-6',
      'wh-text-error-main',
      'hover:wh-text-error-75',
      'hover:wh-bg-error-light',
      'hover:wh-border-error-75',
      'active:wh-text-error-75',
      'active:wh-bg-error-light',
      'active:wh-border-error-75',
      'disabled:wh-text-error-25',
      'disabled:wh-border-error-25',
      'disabled:hover:wh-text-error-25',
      'disabled:hover:wh-border-error-25',
      'disabled:hover:wh-bg-white',
    ),
  },
  [ButtonTypes.CLIP]: {
    [ButtonVariants.CONTAINED]: clsx(
      'wh-p-2',
      'md:wh-p-3',
      'wh-border-0',
      'wh-bg-primary-3 wh-text-primary-main',
      'hover:wh-bg-primary-25 hover:wh-text-primary-75',
      'active:wh-bg-primary-5 active:wh-text-primary-75',
      'disabled:wh-bg-primary-25 disabled:hover:wh-bg-primary-25',
    ),
    [ButtonVariants.OUTLINED]: undefined,
  },
};

export const spinnerSize = {
  [ButtonSizes.SMALL]: SpinnerSizes.SMALL,
  [ButtonSizes.MEDIUM]: SpinnerSizes.MEDIUM,
  [ButtonSizes.BIG]: SpinnerSizes.MEDIUM,
};

export const spinnerType = {
  [ButtonTypes.PRIMARY]: {
    [ButtonVariants.CONTAINED]: SpinnerTypes.WHITE,
    [ButtonVariants.OUTLINED]: SpinnerTypes.PRIMARY_LIGHT,
  },
  [ButtonTypes.SECONDARY]: {
    [ButtonVariants.CONTAINED]: SpinnerTypes.SECONDARY,
    [ButtonVariants.OUTLINED]: SpinnerTypes.SECONDARY,
  },
  [ButtonTypes.NEGATIVE]: {
    [ButtonVariants.CONTAINED]: SpinnerTypes.WHITE,
    [ButtonVariants.OUTLINED]: SpinnerTypes.NEGATIVE,
  },
  [ButtonTypes.CLIP]: {
    [ButtonVariants.CONTAINED]: SpinnerTypes.PRIMARY,
    [ButtonVariants.OUTLINED]: SpinnerTypes.SECONDARY,
  },
};
