import clsx from 'clsx';
import { TextSizes } from '../../../common/enums/TextSizes.enum';

export const chipStyle = clsx('wh-flex', 'wh-flex-wrap', 'wh-justify-center');

export const chipLabelStyle = clsx(
  'wh-px-3',
  'wh-py-1',
  'wh-rounded-full',
  'wh-font-medium',
  `wh-text-${TextSizes.CHIP_SMALL}`,
  'wh-flex',
  'wh-align-center',
  'wh-w-max',
);

export const setChipType = (type: string) =>
  clsx(`wh-bg-${type}-light`, `wh-text-${type}-dark`);
