import clsx from 'clsx';

export const modalCardStyle = clsx(
  'first:wh-pt-0',
  'wh-py-2',
  'wh-capitalize',
  'wh-overflow-hidden',
  'wh-border-b',
  'wh-border-gray-25',
  'last:wh-border-b-0',
  'last:wh-pb-0',
);

export const modalCardStyleInner = clsx('wh-flex', 'wh-py-2');

export const rightCells = clsx(
  'wh-flex',
  'wh-justify-end',
  'wh-items-end',
  'wh-w-full',
);

export const leftCells = clsx(
  'wh-flex',
  'wh-justify-start',
  'wh-items-start',
  'wh-w-full',
);
