import clsx from 'clsx';

export const emptyStyle = clsx(
  'wh-flex',
  'wh-bg-white',
  'wh-flex-col',
  'wh-items-center',
);

export const imageStyle = clsx('wh-mt-2', 'wh-m-1');

export const textStyle = clsx('wh-m-1');

export const primaryTextStyle = clsx('wh-text-h5', 'wh-mt-6');
export const secondaryTextStyle = clsx('wh-mb-6');
