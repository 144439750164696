export enum ButtonSizes {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum ButtonVariants {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export enum ButtonTypes {
  PRIMARY = 'primary',
  CLIP = 'clip',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
}
