import React from 'react';
import { DataGridCellBaseProps } from '../../DataGridCell.types';
import { WhTag } from '../../../Containers/WhTag';

export type DataGridTagCellProps = {};
export const DataGridTagCell: React.FC<
  DataGridCellBaseProps<string, DataGridTagCellProps>
> = ({ value }) => {
  return <WhTag text={value} type="primary" />;
};
