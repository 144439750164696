import React, { FC, useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import {
  ColumnDef,
  ColumnSort,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderGroup,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  headStyles,
  hiddenOnMobile,
  showOnMobile,
  tableStyle,
  typeStyles,
} from './WhDataGrid.styles';
import { DataGridTypes } from './enums';
import {
  DataGridHeaderGroup,
  DataGridRowModel,
  DataGridCellWrapper,
  DataGridHeaderWrapper,
} from './components';
import { DataGridEmpty } from './components/DataGridEmpty';
import { DataGridError } from './components/DataGridError';
import { WhDataGridProps } from './WhDataGrid.types';
import { DataGridPagination } from './components/DataGridPagination';

export const DataGrid: FC<WhDataGridProps> = ({
  columns,
  dataGridType = DataGridTypes.AUTO,
  loadServerRows,
  fetchSize = 10,
  mobileViewContent,
  emptyConfig,
  errorConfig,
  ancestorRef,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isFetchFailed, setIsFetchFailed] = useState(false);
  const [data, setData] = useState<RowData[]>([]);
  const [columnsTyped, setColumnsTyped] = useState<ColumnDef<any, any>[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [page, setPage] = useState(0);

  const firstPage = 0;
  const lastPage = Math.ceil(totalItems / fetchSize) - 1;

  const query = useCallback(
    async ({
      pageParam = 0,
      fetchSize,
      sorting,
    }: {
      pageParam: number;
      fetchSize: number;
      sorting: ColumnSort[];
    }) => {
      try {
        const fetchedData = await loadServerRows(pageParam, fetchSize, sorting); //pretend api call
        setTotalItems(fetchedData.totalItems);
        setData(fetchedData.data);
      } catch (e) {
        setIsFetchFailed(true);
      } finally {
        setFirstLoad(false);
      }
    },
    [loadServerRows],
  );

  useEffect(() => {
    const columnHelper = createColumnHelper<typeof data>();
    setColumnsTyped(
      columns.map(column =>
        columnHelper.accessor(column.field as any, {
          cell: cellContext => {
            const { type, ...props } = column.cell || {};
            return (
              <DataGridCellWrapper
                type={column.cell?.type}
                value={cellContext.getValue()}
                data={cellContext.row.original}
                cellProps={props}
              />
            );
          },
          header: () => {
            const { type, ...props } = column.header || {};
            return (
              <DataGridHeaderWrapper
                type={type}
                title={column.header.title}
                headerProps={props}
              />
            );
          },
          enableSorting: column.sortable,
        }),
      ),
    );
  }, [columns]);

  const table = useReactTable({
    data,
    columns: columnsTyped as any,
    state: {
      sorting,
      pagination: {
        pageIndex: page,
        pageSize: fetchSize,
      },
    },
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableColumnResizing: false,
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: lastPage + 1,
    autoResetPageIndex: false,
  });

  useEffect(() => {
    query({ pageParam: page, sorting, fetchSize });
  }, [fetchSize, page, query, sorting]);

  useEffect(() => {
    if (!firstLoad) {
      ancestorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ancestorRef, data]);

  if (isFetchFailed) {
    return (
      <DataGridError
        principalText={errorConfig?.principalText}
        secondaryText={errorConfig?.secondaryText}
        refreshText={errorConfig?.refreshText}
        refreshFn={() => query({ pageParam: page, sorting, fetchSize })}
      />
    );
  }

  const getNextPage = () => {
    setPage(page + 1);
  };
  const getPreviousPage = () => {
    setPage(page - 1);
  };
  const getFirstPage = () => {
    setPage(firstPage);
  };
  const getLastPage = () => {
    setPage(lastPage);
  };

  return (
    <div id="whale-datagrid-content">
      {table.getRowModel().rows.length === 0 && !firstLoad ? (
        <>
          <table className={clsx(typeStyles[dataGridType], tableStyle)}>
            <>
              <thead className={clsx(headStyles, hiddenOnMobile)}>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<unknown>) => (
                    <DataGridHeaderGroup
                      key={headerGroup.id}
                      group={headerGroup}
                    />
                  ))}
              </thead>
            </>
          </table>
          <div>
            <DataGridEmpty
              principalText={emptyConfig?.principalText}
              secondaryText={emptyConfig?.secondaryText}
            />
          </div>
        </>
      ) : (
        <div>
          <table className={clsx(typeStyles[dataGridType], tableStyle)}>
            <>
              <thead className={clsx(headStyles, hiddenOnMobile)}>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<unknown>) => (
                    <DataGridHeaderGroup
                      key={headerGroup.id}
                      group={headerGroup}
                    />
                  ))}
              </thead>
              <tbody>
                <DataGridRowModel
                  rowModel={table.getRowModel()}
                  mobileViewContent={mobileViewContent}
                />
              </tbody>
              <tfoot className={`${hiddenOnMobile} md:wh-table-header-group`}>
                <tr>
                  <td colSpan={100} className="wh-pt-3">
                    <DataGridPagination
                      table={table}
                      page={page}
                      getFirstPage={getFirstPage}
                      getPreviousPage={getPreviousPage}
                      getNextPage={getNextPage}
                      getLastPage={getLastPage}
                    />
                  </td>
                </tr>
              </tfoot>
            </>
          </table>
          {
            <div className={`${showOnMobile} wh-pt-4 wh-pb-6 wh-px-2.5`}>
              <DataGridPagination
                table={table}
                page={page}
                getFirstPage={getFirstPage}
                getPreviousPage={getPreviousPage}
                getNextPage={getNextPage}
                getLastPage={getLastPage}
              />
            </div>
          }
        </div>
      )}
    </div>
  );
};
