import React, { FC } from 'react';
import { DataGridEmptyProps } from './DataGridEmpty.types';
import { DataGridEmptyImg } from './dataGridEmptyImg';
import {
  emptyStyle,
  imageStyle,
  primaryTextStyle,
  secondaryTextStyle,
  textStyle,
} from './DataGridEmpty.styles';
import { Text } from '../../../Text';
import clsx from 'clsx';

export const DataGridEmpty: FC<DataGridEmptyProps> = ({
  principalText,
  secondaryText,
}) => {
  return (
    <div className={emptyStyle}>
      <div className={imageStyle}>
        <DataGridEmptyImg />
      </div>
      <Text className={clsx(textStyle, primaryTextStyle)}>{principalText}</Text>
      <Text className={clsx(secondaryTextStyle, textStyle)} weight={'light'}>
        {secondaryText}
      </Text>
    </div>
  );
};
