import React from 'react';
import { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { hiddenOnMobile } from '../../WhDataGrid.styles';

interface DataGridPaginationProps {
  table: Table<unknown>;
  page: number;
  getFirstPage: () => void;
  getPreviousPage: () => void;
  getNextPage: () => void;
  getLastPage: () => void;
}

const iconStyle = 'wh-text-h4 wh-w-4 wh-h-4';

export const DataGridPagination: React.FC<DataGridPaginationProps> = ({
  table,
  page,
  getFirstPage,
  getPreviousPage,
  getNextPage,
  getLastPage,
}) => {
  const hasPreviousPage = page > 0;
  const hasNextPage = page < table.getPageCount() - 1;
  const fetchSize = table.getRowModel().rows.length;

  return table.getPageCount() > 1 ? (
    <div className="wh-flex sm:wh-justify-between md:wh-justify-end">
      <div className="sm:wh-flex wh-items-center sm:wh-mr-0 md:wh-mr-14">
        <span
          className={clsx(
            hiddenOnMobile,
            'md:wh-inline-flex lg:wh-inline-flex xl:wh-inline-flex wh-mr-12',
          )}
        >
          {`Rows per page: ${fetchSize}`}
        </span>
        <span>{`${page + 1} of ${table.getPageCount()} pages`}</span>
      </div>
      <div className="md:wh-mr-5">
        <button
          className={`sm:wh-mr-3 md:wh-mr-7 wh-font-light wh-text-h3 ${
            hasPreviousPage ? '' : 'wh-text-neutral-light'
          }`}
          onClick={getFirstPage}
          disabled={!hasPreviousPage}
        >
          {<i className={clsx(`wh-icon wh-icon-arrow-first`, iconStyle)} />}
        </button>
        <button
          className={`sm:wh-mr-6 md:wh-mr-12 wh-font-light wh-text-h3 ${
            hasPreviousPage ? '' : 'wh-text-neutral-light'
          }`}
          onClick={getPreviousPage}
          disabled={!hasPreviousPage}
        >
          {<i className={clsx(`wh-icon wh-icon-arrow-left`, iconStyle)} />}
        </button>
        <button
          className={`sm:wh-mr-3 md:wh-mr-7 wh-font-light wh-text-h3 ${
            hasNextPage ? '' : 'wh-text-neutral-light'
          }`}
          onClick={getNextPage}
          disabled={!hasNextPage}
        >
          {<i className={clsx(`wh-icon wh-icon-arrow-right`, iconStyle)} />}
        </button>
        <button
          className={`wh-font-light wh-text-h3 ${
            hasNextPage ? '' : 'wh-text-neutral-light'
          }`}
          onClick={getLastPage}
          disabled={!hasNextPage}
        >
          {<i className={clsx(`wh-icon wh-icon-arrow-last`, iconStyle)} />}
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};
