import React from 'react';
import { FontWeight, TextSize, Text } from '../../../Text';
import { DataGridHeaderBaseProps } from '../../DataGridHeader.types';

export type DataGridTextHeaderProps = {
  size?: TextSize;
  weight?: FontWeight;
};
export const DataGridTextHeader: React.FC<
  DataGridHeaderBaseProps<DataGridTextHeaderProps>
> = ({ title, headerProps }) => {
  return (
    <Text size="label" weight="regular" {...headerProps}>
      {title}
    </Text>
  );
};
