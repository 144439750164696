export enum SpinnerTypes {
  WHITE = 'white',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
  PRIMARY = 'primary',
  PRIMARY_LIGHT = 'primary-light',
}

export enum SpinnerSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}
