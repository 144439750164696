import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { WhTooltipProps } from './WhTooltip.types';
import {
  containerStyle,
  relativePosition,
  staticPosition,
  contentStyle,
  topLeftStyle,
  topRightStyle,
  topCenterStyle,
  bottomLeftStyle,
  bottomRightStyle,
  bottomCenterStyle,
} from './WhTooltip.styles';
import { BackDrop } from './BackDrop';
import media from '../../../common/utils/media';

export const WhTooltip = ({
  title,
  text,
  position,
  children,
}: WhTooltipProps) => {
  const isMobile = media.isMobile();
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [tooltipPositionRect, setTooltipPositionRect] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBackDropClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (tooltipRef.current?.getBoundingClientRect()) {
      setTooltipPositionRect(tooltipRef.current?.getBoundingClientRect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current, isOpen]);

  const anchorPositionDictionary: { [position: string]: any } = {
    topLeft: topLeftStyle,
    topRight: topRightStyle,
    topCenter: topCenterStyle,
    bottomLeft: bottomLeftStyle,
    bottomRight: bottomRightStyle,
    bottomCenter: bottomCenterStyle,
  };

  const tooltipPositionDictionary: { [position: string]: any } = {
    topLeft: 'wh-justify-start',
    topRight: 'wh-justify-end',
    topCenter: 'wh-justify-center',
    bottomLeft: 'wh-justify-start',
    bottomRight: 'wh-justify-end',
    bottomCenter: 'wh-justify-center',
  };

  return (
    <div
      className={clsx(
        isMobile ? staticPosition : relativePosition,
        containerStyle,
        tooltipPositionDictionary[position],
      )}
    >
      {isMobile && (
        <BackDrop
          isOpen={isOpen}
          handleBackDropClose={handleBackDropClose}
          title={title}
          text={text}
        />
      )}

      {!isMobile && isOpen && (
        <div
          ref={tooltipRef}
          style={{
            ...(position.includes('top') && {
              //@ts-ignore
              top: `-${tooltipPositionRect.height + 2}px`,
            }),
            ...(position.includes('bottom') && {
              //@ts-ignore
              bottom: `-${tooltipPositionRect.height + 6}px`,
            }),
          }}
          className={clsx(contentStyle, anchorPositionDictionary[position])}
        >
          <span>{text}</span>
        </div>
      )}
      <div
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => !isMobile && setIsOpen(true)}
        onMouseLeave={() => !isMobile && setIsOpen(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default WhTooltip;
