import React, { FC } from 'react';
import clsx from 'clsx';

import { SpinnerProps } from './Spinner.types';
import { SpinnerStrokeStyles, SpinnerDimensionStyles } from './Spinner.styles';

export const Spinner: FC<SpinnerProps> = ({
  size,
  type = 'primary',
  color,
  className,
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(SpinnerDimensionStyles[size], 'wh-animate-spin', className)}
  >
    <mask id="path-1-inside-1_1646_902" fill="white">
      <path d="M22.1998 12C23.194 12 24.0138 11.1895 23.8652 10.2065C23.5556 8.15808 22.719 6.21382 21.4238 4.57087C19.7551 2.45411 17.4223 0.960796 14.8013 0.331561C12.1804 -0.297674 9.42392 -0.026185 6.97608 1.10228C4.52825 2.23075 2.53162 4.15047 1.30792 6.55211C0.0842238 8.95376 -0.295275 11.6974 0.230576 14.3411C0.756428 16.9847 2.157 19.3743 4.20662 21.1249C6.25624 22.8754 8.83553 23.8849 11.5289 23.9907C13.6193 24.0729 15.684 23.6069 17.5232 22.6534C18.4059 22.1958 18.5797 21.0562 17.9953 20.2518C17.4109 19.4475 16.2891 19.2901 15.3789 19.69C14.2191 20.1996 12.9516 20.4435 11.6702 20.3931C9.78498 20.319 7.97956 19.6124 6.5449 18.3871C5.11024 17.1618 4.12988 15.4891 3.7618 13.6387C3.39372 11.7882 3.65936 9.86773 4.51591 8.18666C5.37245 6.50559 6.77003 5.16185 8.48343 4.37197C10.1968 3.58208 12.1263 3.39204 13.9608 3.83249C15.7954 4.27293 17.4283 5.3182 18.5963 6.79986C19.3902 7.8069 19.938 8.97568 20.2074 10.2135C20.4189 11.185 21.2056 12 22.1998 12Z" />
    </mask>
    <path
      d="M22.1998 12C23.194 12 24.0138 11.1895 23.8652 10.2065C23.5556 8.15808 22.719 6.21382 21.4238 4.57087C19.7551 2.45411 17.4223 0.960796 14.8013 0.331561C12.1804 -0.297674 9.42392 -0.026185 6.97608 1.10228C4.52825 2.23075 2.53162 4.15047 1.30792 6.55211C0.0842238 8.95376 -0.295275 11.6974 0.230576 14.3411C0.756428 16.9847 2.157 19.3743 4.20662 21.1249C6.25624 22.8754 8.83553 23.8849 11.5289 23.9907C13.6193 24.0729 15.684 23.6069 17.5232 22.6534C18.4059 22.1958 18.5797 21.0562 17.9953 20.2518C17.4109 19.4475 16.2891 19.2901 15.3789 19.69C14.2191 20.1996 12.9516 20.4435 11.6702 20.3931C9.78498 20.319 7.97956 19.6124 6.5449 18.3871C5.11024 17.1618 4.12988 15.4891 3.7618 13.6387C3.39372 11.7882 3.65936 9.86773 4.51591 8.18666C5.37245 6.50559 6.77003 5.16185 8.48343 4.37197C10.1968 3.58208 12.1263 3.39204 13.9608 3.83249C15.7954 4.27293 17.4283 5.3182 18.5963 6.79986C19.3902 7.8069 19.938 8.97568 20.2074 10.2135C20.4189 11.185 21.2056 12 22.1998 12Z"
      className={clsx({ [SpinnerStrokeStyles[type]]: !color })}
      stroke={color}
      strokeWidth="12"
      strokeLinejoin="round"
      mask="url(#path-1-inside-1_1646_902)"
    />
  </svg>
);
