import React from 'react';
import { DataGridCellBaseProps } from '../../DataGridCell.types';
import { Text } from '../../../Text';
import {
  cellDefault,
  cellNegative,
  cellPositive,
  iconStyle,
  percentageCell,
} from './DataGridPercentageCell.styles';
import clsx from 'clsx';

export type DataGridPercentageCellProps = {
  percentageType: 'with-color' | 'default';
  maximumFractionDigits?: number;
  showIcon?: boolean;
};
export const DataGridPercentageCell: React.FC<
  DataGridCellBaseProps<never, DataGridPercentageCellProps>
> = ({ value, cellProps }) => {
  const formattedValue = parseFloat(value);
  const setClass = () => {
    if (cellProps.percentageType === 'with-color') {
      if (formattedValue > 0) {
        return cellPositive;
      } else if (formattedValue < 0) {
        return cellNegative;
      }
    }
    return cellDefault;
  };
  const setSymbol = () => {
    if (cellProps.showIcon) {
      if (formattedValue > 0) {
        return <i className={clsx('wh-icon-add', iconStyle)}></i>;
      } else if (formattedValue < 0) {
        return <i className={clsx('wh-icon-minus', iconStyle)}></i>;
      }
    }
    return null;
  };

  return (
    <Text size="body1" className={clsx(setClass(), percentageCell)}>
      {setSymbol()}
      {Math.abs(formattedValue).toFixed(cellProps.maximumFractionDigits || 2)}%
    </Text>
  );
};
