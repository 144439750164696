import React from 'react';
import { Text } from '../../../Text';
import { DataGridCellBaseProps } from '../../DataGridCell.types';

export type DataGridHeadingCellProps = {
  showIcon?: boolean;
  iconType: React.FC;
};
export const DataGridHeadingCell: React.FC<
  DataGridCellBaseProps<string, DataGridHeadingCellProps>
> = ({ value, cellProps, data }) => {
  const { showIcon, iconType } = cellProps;

  return (
    <div className="wh-flex wh-gap-x-3">
      {showIcon && iconType(data)}
      <Text
        size="body1"
        weight="medium"
        {...cellProps}
        className="wh-flex wh-justify-center wh-items-center"
      >
        {value}
      </Text>
    </div>
  );
};
