import React from 'react';
import {
  DataGridHeaderTypes,
  DataGridHeaderBaseProps,
} from '../../DataGridHeader.types';
import { DataGridTextHeader } from '../../headers';

const Headers: Record<DataGridHeaderTypes, Function> = {
  TEXT: DataGridTextHeader,
};

const DefaultHeader = Headers['TEXT'];

export type DataGridHeaderWrapperProps = {
  type?: DataGridHeaderTypes;
} & DataGridHeaderBaseProps;
export const DataGridHeaderWrapper: React.FC<DataGridHeaderWrapperProps> = ({
  type,
  ...props
}) => {
  if (type) {
    const Header = Headers[type];

    if (Header) return <Header {...props} />;
  }

  return <DefaultHeader {...props} />;
};
